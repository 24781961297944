// import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { useSelector, useDispatch } from 'react-redux'
// import {} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: 'grey'
  }
}))

const PersonView = () => {
  const classes = useStyles()

  return <div className={classes.root}>VISTA PEATONAL</div>
}
export default PersonView
