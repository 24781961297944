import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useSelector, useDispatch } from 'react-redux'
import States from './States'
import Floor from './Floor'
import Price from './Price'
import Typology from './Typology'
import TwoButtons from '../Common/TwoButtons'
import Favorites from './Favorites'
import {
  filterListViewModeSelector,
  setFilterListViewMode
} from '../../modules/reducerMain'
import { setGroup } from '../../modules/reducerRotatorGroups'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    color: theme.palette.primary.contrastText,
    marginLeft: theme.sizes.menuPaddingLeft
  },
  textTop: { marginBottom: '12px' }
}))

const FilterMenu = () => {
  const classes = useStyles()
  const filterListViewMode = useSelector(filterListViewModeSelector)
  const dispatch = useDispatch()

  const handleMode = useCallback(
    (side) => {
      dispatch(setFilterListViewMode(side === 'left'))
      if (side !== 'left') {
        dispatch(setGroup('g0'))
      }
    },
    [dispatch]
  )

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.textTop}>
        Selecciona tipo de vista
      </Typography>
      <TwoButtons
        leftText="Lista"
        rightText="3D"
        leftSelected={filterListViewMode}
        width={150}
        onClick={handleMode}
      />
      <Favorites />
      <Typology />
      <Floor />
      <Price />
      <States />
    </div>
  )
}
export default FilterMenu
