import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import { getCurrentHouse } from '../../modules/reducerHouses'
import { setCurrentSubmenu, SUBMENUS } from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 24,
    height: '24px',
    width: 190,
    margin: 0
  },
  button: { textTransform: 'none', width: '50%', padding: 0 },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main
    }
  }
}))

const DocumentsGallery = () => {
  const classes = useStyles({})
  const house = useSelector(getCurrentHouse)
  const dispatch = useDispatch()

  const handleDocumentsClick = useCallback(() => {
    dispatch(setCurrentSubmenu(SUBMENUS.DOCUMENTOS_VIVIENDA))
  }, [dispatch])

  const handleGalleryClick = useCallback(() => {
    dispatch(setCurrentSubmenu(SUBMENUS.GALERIAS_VIVIENDA))
  }, [dispatch])

  return house && (house.gallery || house.documents.length > 0) ? (
    <ButtonGroup color="secondary" className={classes.root}>
      <Button
        className={classes.button}
        disabled={house.documents.length === 0}
        onClick={handleDocumentsClick}
      >
        <Typography variant="h4">Documentos</Typography>
      </Button>
      <Button
        className={classes.button}
        disabled={!house.gallery}
        onClick={handleGalleryClick}
      >
        <Typography variant="h4">Galería</Typography>
      </Button>
    </ButtonGroup>
  ) : null
}
export default DocumentsGallery
