import { useCallback, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

import {
  currentPageIdxSelector,
  setPageIdx,
  pageCountSelector
} from '../../modules/reducerPDFViewer'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    paddingRight: 8
  },
  select: {
    width: '100px',
    height: '25px',
    backgroundColor: 'rgb(190,190,190)'
  },
  pageInput: {
    textAlign: 'center',
    padding: 0,
    width: '50px',
    height: '40px',
    margin: '0px',
    outline: 'none',
    border: 'none',
    borderColor: theme.palette.primary.main,
    '&:hover': { borderColor: theme.palette.secondary.main }
  }
}))

const PDFPageInput = (props) => {
  const [localPage, setLocalPage] = useState(0)
  const classes = useStyles()
  const page = useSelector(currentPageIdxSelector)
  const pageCount = useSelector(pageCountSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    setLocalPage(page + 1)
  }, [page])

  const handleChange = (e) => {
    setLocalPage(e.target.value)
  }

  const updatePage = useCallback(() => {
    if (isNaN(localPage)) {
      setLocalPage(page + 1)
    } else {
      dispatch(setPageIdx(parseInt(localPage) - 1))
    }
  }, [dispatch, localPage, page])

  const handleBlur = useCallback(
    (e) => {
      updatePage()
    },
    [updatePage]
  )

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        updatePage()
      }
    },
    [updatePage]
  )

  return (
    <Tooltip title={'Página'} arrow>
      <Button
        {...props}
        className={`${props.className} ${classes.root}`}
        component="div"
      >
        <input
          className={classes.pageInput}
          type="text"
          value={localPage}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
        />
        <span>de {pageCount}</span>
      </Button>
    </Tooltip>
  )
}
export default PDFPageInput
