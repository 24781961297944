import { createSelector } from 'reselect'

const SMALL_WIDTH = 850

export const SET_MENU_OPEN = 'SET_MENU_OPEN'
export const SET_CURRENT_MENU_OPTION = 'SET_CURRENT_MENU_OPTION'
export const SET_CURRENT_SUBMENU = 'SET_CURRENT_SUBMENU'
export const HOME_CLICKED = 'HOME_CLICKED'
export const BACK_CLICKED = 'BACK_CLICKED'
export const OPTION_CLICKED = 'OPTION_CLICKED'
export const SET_FILTER_LIST_VIEW_MODE = 'SET_FILTER_LIST_VIEW_MODE'
export const SET_PLANO_3D_MODE = 'SET_PLANO_3D_MODE'

export const SET_MENU_OPTION_EXPANDED = 'SET_MENU_OPTION_EXPANDED'

export const SET_ROOT_SIZE = 'SET_ROOT_SIZE'
export const SET_DRAG_ENABLED = 'SET_DRAG_ENABLED'

export const MENU_OPTIONS = {
  VISTA_GENERAL: 'VISTA_GENERAL',
  PLANO_SITUACION: 'PLANO_SITUACION',
  VISTA_AEREA: 'VISTA_AEREA',
  VISTA_PEATONAL: 'VISTA_PEATONAL',
  SELECCIONA_VIVIENDA: 'SELECCIONA_VIVIENDA',
  VISITAS_VIRTUALES: 'VISITAS_VIRTUALES',
  GALERIA_IMAGENES: 'GALERIA_IMAGENES',
  VIDEOS: 'VIDEOS',
  DESCARGAS: 'DESCARGAS',
  DOCUMENTOS: 'DOCUMENTOS'
}

export const SUBMENUS = {
  PLANO_SITUACION: 'PLANO_SITUACION',
  FILTRO: 'FILTRO',
  DETALLES_CASA: 'DETALLES_CASA',
  DOCUMENTOS_PROMOCION: 'DOCUMENTOS_PROMOCION',
  DOCUMENTOS_VIVIENDA: 'DOCUMENTOS_VIVIENDA',
  GALERIAS_PROMOCION: 'GALERIAS_PROMOCION',
  GALERIAS_VIVIENDA: 'GALERIAS_VIVIENDA',
  VIDEOS: 'VIDEOS',
  DESCARGAS: 'DESCARGAS'
}

const initialState = {
  menuOptions: [
    /*
    {
      id: 'id',
      name: 'name',
      submenu: '',  Menu al que se va cuando se selecciona
      expandable: true/false  Si true se expande el menu indicado por submenu
                              Si false y hay submenu se abre el submenu
    },
    */
    {
      id: MENU_OPTIONS.VISTA_GENERAL,
      name: 'Vista general',
      submenu: '',
      expandable: false
    },
    {
      id: MENU_OPTIONS.PLANO_SITUACION,
      name: 'Plano de situación',
      submenu: SUBMENUS.PLANO_SITUACION,
      expandable: false
    },
    {
      id: MENU_OPTIONS.VISTA_AEREA,
      name: 'Vista aérea',
      submenu: '',
      expandable: false
    },
    {
      id: MENU_OPTIONS.VISTA_PEATONAL,
      name: 'Vista peatonal',
      submenu: '',
      expandable: false
    },
    {
      id: MENU_OPTIONS.SELECCIONA_VIVIENDA,
      name: 'Selecciona tu vivienda',
      submenu: SUBMENUS.FILTRO,
      expandable: false
    },
    {
      id: MENU_OPTIONS.VISITAS_VIRTUALES,
      name: 'Visitas virtuales',
      submenu: '',
      expandable: false
    },
    {
      id: MENU_OPTIONS.GALERIA_IMAGENES,
      name: 'Galería de imágenes',
      submenu: SUBMENUS.GALERIAS_PROMOCION,
      expandable: false
    },
    {
      id: MENU_OPTIONS.VIDEOS,
      name: 'Vídeos',
      submenu: SUBMENUS.VIDEOS,
      expandable: true
    },
    {
      id: MENU_OPTIONS.DESCARGAS,
      name: 'Gestor de descargas',
      submenu: SUBMENUS.DESCARGAS,
      expandable: true
    },
    {
      id: MENU_OPTIONS.DOCUMENTOS,
      name: 'Visor documentos',
      submenu: SUBMENUS.DOCUMENTOS_PROMOCION,
      expandable: false
    }
  ],
  submenus: [
    /*
    {
      id: 'id',
      menuOption: '', Icono que se muestra arriba
      parentSubmenu: '' Submenu al que vuelve cuando se pulsa back. Si no hay,
                        se vuelve al menu principal
    },
    */
    {
      id: SUBMENUS.PLANO_SITUACION,
      menuOption: MENU_OPTIONS.PLANO_SITUACION,
      parentSubmenu: ''
    },
    {
      id: SUBMENUS.FILTRO,
      menuOption: MENU_OPTIONS.SELECCIONA_VIVIENDA,
      parentSubmenu: ''
    },
    {
      id: SUBMENUS.DETALLES_CASA,
      menuOption: MENU_OPTIONS.SELECCIONA_VIVIENDA,
      parentSubmenu: SUBMENUS.FILTRO
    },
    {
      id: SUBMENUS.DOCUMENTOS_VIVIENDA,
      menuOption: MENU_OPTIONS.DOCUMENTOS,
      parentSubmenu: SUBMENUS.DETALLES_CASA
    },
    {
      id: SUBMENUS.DOCUMENTOS_PROMOCION,
      menuOption: MENU_OPTIONS.DOCUMENTOS,
      parentSubmenu: ''
    },
    {
      id: SUBMENUS.GALERIAS_VIVIENDA,
      menuOption: MENU_OPTIONS.GALERIA_IMAGENES,
      parentSubmenu: SUBMENUS.DETALLES_CASA
    },
    {
      id: SUBMENUS.GALERIAS_PROMOCION,
      menuOption: MENU_OPTIONS.GALERIA_IMAGENES,
      parentSubmenu: ''
    },
    {
      id: SUBMENUS.VIDEOS,
      menuOption: MENU_OPTIONS.VIDEOS,
      parentSubmenu: ''
    }
  ],
  rootSize: { width: 0, height: 0 },
  menuOpen: true,
  currentMenuOption: MENU_OPTIONS.VISTA_GENERAL,
  currentMenuOptionExpanded: false,
  currentSubmenu: '',
  filterListViewMode: true,
  houseDetailsPlano3DMode: false,
  dragEnabled: true
}

const changeState = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case SET_ROOT_SIZE:
      return {
        ...state,
        rootSize: action.size,
        menuOpen: action.size.width > SMALL_WIDTH ? true : state.menuOpen
      }

    case SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.open
      }

    case SET_CURRENT_MENU_OPTION: {
      const option = state.menuOptions.find((mo) => mo.id === action.option)
      return {
        ...state,
        currentMenuOption: option ? action.option : state.currentMenuOption,
        currentSubmenu: option && !option.expandable ? option.submenu : ''
      }
    }

    case SET_CURRENT_SUBMENU: {
      const submenu = state.submenus.find((sm) => sm.id === action.submenu)
      if (!submenu) {
        return state
      }
      return {
        ...state,
        currentMenuOption: submenu.menuOption,
        currentSubmenu: action.submenu
      }
    }

    case OPTION_CLICKED:
      const option = state.menuOptions.find((mo) => mo.id === action.option)
      if (!option) {
        return state
      }
      return {
        ...state,
        menuOpen: true,
        currentMenuOption: action.option,
        currentSubmenu: !option.expandable ? option.submenu : '',
        currentMenuOptionExpanded:
          state.currentMenuOption === action.option
            ? !state.currentMenuOptionExpanded
            : option && option.expandable
      }

    case HOME_CLICKED:
      return {
        ...state,
        currentMenuOption: MENU_OPTIONS.VISTA_GENERAL,
        currentSubmenu: ''
      }

    case BACK_CLICKED: {
      if (state.currentSubmenu !== '') {
        const submenu = state.submenus.find(
          (sm) => sm.id === state.currentSubmenu
        )
        if (submenu) {
          if (submenu.parentSubmenu !== '') {
            const parentSubmenu = state.submenus.find(
              (sm) => sm.id === submenu.parentSubmenu
            )
            return {
              ...state,
              currentMenuOption: parentSubmenu.menuOption,
              currentSubmenu: submenu.parentSubmenu
            }
          }
          return {
            ...state,
            currentMenuOption: MENU_OPTIONS.VISTA_GENERAL,
            currentSubmenu: ''
          }
        }
      }
      return { ...state, currentMenuOption: '', submenu: '' }
    }

    case SET_MENU_OPTION_EXPANDED:
      return {
        ...state,
        currentMenuOptionExpanded: action.id
      }

    case SET_FILTER_LIST_VIEW_MODE:
      return { ...state, filterListViewMode: action.on }

    case SET_PLANO_3D_MODE:
      return { ...state, houseDetailsPlano3DMode: action.on }
    case SET_DRAG_ENABLED:
      return { ...state, dragEnabled: action.enabled }
    default:
      return state
  }
}
export default changeState

export const setRootSize = (size) => ({
  type: SET_ROOT_SIZE,
  size
})

export const setMenuOpen = (open) => ({
  type: SET_MENU_OPEN,
  open
})

export const setCurrentMenuOption = (option) => ({
  type: SET_CURRENT_MENU_OPTION,
  option
})

export const setCurrentSubmenu = (submenu) => ({
  type: SET_CURRENT_SUBMENU,
  submenu
})

export const optionClicked = (option) => ({
  type: OPTION_CLICKED,
  option
})

export const homeClicked = () => ({
  type: HOME_CLICKED
})

export const backClicked = () => ({
  type: BACK_CLICKED
})

export const setFilterListViewMode = (on) => ({
  type: SET_FILTER_LIST_VIEW_MODE,
  on
})

export const setPlano3dModeMode = (on) => ({
  type: SET_PLANO_3D_MODE,
  on
})

export const setMenuOptionExpanded = (id) => ({
  type: SET_MENU_OPTION_EXPANDED,
  id
})

export const setDragEnabled = (enabled) => ({
  type: SET_DRAG_ENABLED,
  enabled
})

export const rootSizeSelector = (state) => state.main.rootSize
export const menuOpenSelector = (state) => state.main.menuOpen
export const menuOptionsSelector = (state) => state.main.menuOptions
export const currentMenuOptionSelector = (state) => state.main.currentMenuOption
export const currentSubmenuSelector = (state) => state.main.currentSubmenu
export const filterListViewModeSelector = (state) =>
  state.main.filterListViewMode
export const houseDetailsPlano3DModeSelector = (state) =>
  state.main.houseDetailsPlano3DMode
export const currentMenuOptionExpandedSelector = (state) =>
  state.main.currentMenuOptionExpanded
export const dragEnabledSelector = (state) => state.main.dragEnabled

export const getMenuOption = () =>
  createSelector([menuOptionsSelector, (_, id) => id], (options, id) => {
    return options.find((o) => o.id === id)
  })

export const getMenuOptionExpanded = createSelector(
  [currentMenuOptionExpandedSelector, currentMenuOptionSelector],
  (currentMenuOptionExpanded, currentMenuOption) => (optionId) => {
    return optionId === currentMenuOption && currentMenuOptionExpanded
  }
)

export const getMobileMode = createSelector(
  [rootSizeSelector],
  (rootSize) => rootSize.width < SMALL_WIDTH
)
