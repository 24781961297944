import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText
  },
  list: { paddingLeft: ({ paddingLeft }) => paddingLeft, marginTop: 0 },
  listItem: {
    cursor: 'pointer',
    margin: '8px 0px',
    height: '20px', //Hace un efecto raro cuando se expande si so se pone
    color: ({ selected }) => (selected ? theme.palette.secondary.main : ''),
    '&:hover': { color: theme.palette.secondary.main }
  }
}))

const ListMenuItem = ({ item, selected, onClick }) => {
  const classes = useStyles({
    selected: item && selected.indexOf(item.id) >= 0
  })

  const handleClick = useCallback(() => {
    onClick(item)
  }, [item, onClick])

  return item ? (
    <li className={classes.listItem} onClick={onClick ? handleClick : null}>
      <Typography variant="h3" className={classes.title}>
        {item.name}
      </Typography>
    </li>
  ) : null
}

/*
  items:[{id,name}]
  selected:[id,id]
  onClick(item)
*/
const ListMenu = ({ paddingLeft, items, selected, onClick }) => {
  const classes = useStyles({ paddingLeft })
  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {items.map((item, idx) => (
          <ListMenuItem
            key={idx}
            item={item}
            selected={selected}
            onClick={onClick}
          />
        ))}
      </ul>
    </div>
  )
}
export default ListMenu
/*
<ListMenuItem
  key={idx}
  item={item}
  selected={selected}
  onClick={onClick}
/>

<div className={classes.root}>
  <div className={classes.list}>
    {items.map((item, idx) => (
      <div style={{ width: '100px', height: 30 }}>{item.name}</div>
    ))}
  </div>
</div>

*/
