const SeleccionarViviendaIcon = ({ width, height, ...props }) => {
  return (
    <svg viewBox="0 0 23 27" {...props}>
      <path
        fill="fill"
        d="M22.854 25.748l-2.896-1.956V16.89h2.069a.222.222 0 00.206-.206v-4.12c0-.103-.103-.206-.207-.206h-1.55V6.386l1.654-1.34c.103 0 .103-.102.103-.205V.206c0-.103-.103-.206-.207-.206H5.688c-.207 0-.31.103-.31.206v5.253H0v.515h.724V25.13c0 .102.103.206.207.206h6.928l-.207.412v.308c0 .103.104.103.207.103H22.75c.104 0 .207-.103.207-.206.104 0 0-.102-.103-.206zm-3.31-2.06h-.516v-5.15c0-.102-.104-.205-.207-.205h-2.172v-1.339h2.896v6.695zm-2.895-4.943h1.861v4.944h-1.86v-4.944zm0-6.489V6.49h3.413v5.767h-3.413zm3.62-6.282h-3.62v-.927h4.757l-1.137.927zM5.893.515H21.82v4.017h-5.481c-.104 0-.207.103-.207.206v7.724c0 .103.103.206.207.206h5.48v3.605H5.895V.515zM1.24 24.925V5.973h4.24v10.608c0 .103.103.206.207.206h10.444v6.9H8.997c-.104 0-.207 0-.207.104l-.62 1.132H1.24zm7.032.823l.207-.309h.103v-.102l.62-1.133H19.75l2.275 1.544H8.273z"
      />
      <path
        fill="fill"
        d="M4.653 9.475H2.068c-.103 0-.207.103-.207.206v5.46c0 .205.104.308.207.308H4.55c.103 0 .207-.103.207-.206V9.784c.103-.206 0-.309-.104-.309zm-.31 5.46H2.378V9.99h1.965v4.944zm.31 2.574H2.068c-.103 0-.207.103-.207.206v5.459c0 .103.104.206.207.206H4.55c.103 0 .207-.103.207-.206v-5.46c.103-.102 0-.205-.104-.205zm-.31 5.459H2.378v-4.944h1.965v4.944zm9.927-.309v-4.841a.222.222 0 00-.206-.206H7.239c-.104 0-.207.103-.207.206v4.84c0 .104.103.207.207.207h6.721c.207.103.31 0 .31-.206zm-.516-2.884h-2.896v-1.751h2.896v1.75zm-3.413-1.751v1.75H7.55v-1.75h2.792zM7.55 22.453v-2.06h6.205v2.06H7.549zm7.445-18.127H8.583c-.207 0-.31.103-.31.206V9.99c0 .206.103.31.31.31h6.412c.103 0 .206-.104.206-.207V4.532c.104-.103-.103-.206-.206-.206zm-.207 2.369h-2.689V4.84h2.689v1.854zm-3.31-1.854v1.854H8.79V4.84h2.689zM8.79 9.784V7.21h5.894v2.574H8.79z"
      />
    </svg>
  )
}
export default SeleccionarViviendaIcon
