import { createSelector } from 'reselect'
import {
  housesSelector,
  houseTypologiesSelector,
  houseFloorsSelector,
  houseStatesSelector,
  favoritesSelector,
  getPriceRange
} from './reducerHouses'

export const SELECT_TYPOLOGY = 'SELECT_TYPOLOGY'
export const SET_PRICE_RANGE = 'SET_PRICE_RANGE'
export const SET_FLOOR = 'SET_FLOOR'
export const SELECT_STATE = 'SELECT_STATE'
export const ACTIVE_FAVORITES = 'ACTIVE_FAVORITES'

const initialState = {
  typology: new Set(),
  price: [0, 0], //[min,max]
  floor: '',
  houseState: new Set(),
  favorites: false
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SELECT_TYPOLOGY:
      const typology = new Set(state.typology)
      action.select ? typology.add(action.id) : typology.delete(action.id)
      return {
        ...state,
        typology
      }
    case SET_PRICE_RANGE:
      return { ...state, price: action.range }
    case SET_FLOOR:
      return { ...state, floor: action.floor }
    case SELECT_STATE:
      const houseState = new Set(state.houseState)
      action.select ? houseState.add(action.id) : houseState.delete(action.id)
      return {
        ...state,
        houseState
      }
    case ACTIVE_FAVORITES:
      return { ...state, favorites: action.on }
    default:
      return state
  }
}
export default changeState

export const selectTypology = (id, select) => ({
  type: SELECT_TYPOLOGY,
  id,
  select
})
export const setPriceRange = (range) => ({
  type: SET_PRICE_RANGE,
  range
})
export const setFloor = (floor) => ({
  type: SET_FLOOR,
  floor
})
export const selectHouseState = (id, select) => ({
  type: SELECT_STATE,
  id,
  select
})
export const activeFavorites = (on) => ({
  type: ACTIVE_FAVORITES,
  on
})

export const typologySelector = (state) => state.filter.typology
export const priceSelector = (state) => state.filter.price
export const floorSelector = (state) => state.filter.floor
export const houseStateSelector = (state) => state.filter.houseState
export const favoritesOnSelector = (state) => state.filter.favorites

export const getFilterHouses = createSelector(
  [
    housesSelector,
    typologySelector,
    priceSelector,
    floorSelector,
    houseStateSelector,
    favoritesOnSelector,
    favoritesSelector
  ],
  (houses, typology, price, floor, houseState, favoritesOn, favorites) => {
    return houses.filter((house) => {
      return (
        (!favoritesOn || favorites.has(house.id)) &&
        (typology.size === 0 || typology.has(house.typology)) &&
        (houseState.size === 0 || houseState.has(house.state)) &&
        house.price >= price[0] &&
        house.price <= price[1] &&
        (floor === '' || house.floor === floor)
      )
    })
  }
)

export const getClassifyHousesByTypology = createSelector(
  [housesSelector, houseTypologiesSelector],
  (houses, typologies) => {
    const values = typologies.map((typo) => {
      return { typologyId: typo.id, housesId: [] }
    })
    houses.reduce((acc, house) => {
      const typo = values.find((v) => v.typologyId === house.typology)
      if (typo) {
        typo.housesId.push(house.id)
      }
      return acc
    }, values)
    return values
  }
)

export const getClassifyHousesByFloor = createSelector(
  [housesSelector, houseFloorsSelector],
  (houses, floors) => {
    const values = floors.map((floor) => {
      return { floorId: floor.id, housesId: [] }
    })
    houses.reduce((acc, house) => {
      const typo = values.find((v) => v.floorId === house.floor)
      if (typo) {
        typo.housesId.push(house.id)
      }
      return acc
    }, values)
    return values
  }
)

export const getClassifyHousesByState = createSelector(
  [housesSelector, houseStatesSelector],
  (houses, states) => {
    const values = states.map((state) => {
      return { stateId: state.id, housesId: [] }
    })
    houses.reduce((acc, house) => {
      const state = values.find((v) => v.stateId === house.state)
      if (state) {
        state.housesId.push(house.id)
      }
      return acc
    }, values)
    return values
  }
)

export const getSliderPriceRange = createSelector(
  [getPriceRange],
  (priceRange) => {
    return [Math.max(0, priceRange[0] - 10000), priceRange[1] + 10000]
  }
)
