// import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { useSelector, useDispatch } from 'react-redux'
// import {} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0
  }
}))

const VirtualTour = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <iframe
        className={classes.iframe}
        title="Visita virtual"
        frameBorder="0"
        src="./VisitasVirtuales/1/index.html"
      ></iframe>
    </div>
  )
}
export default VirtualTour
//src="./VisitasVirtuales/1/index.html"
//src="./VT/TB79/LH79.html"
