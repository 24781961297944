// import  { useRef, useEffect, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'

import { getCurrentVideo } from '../../modules/reducerVideos'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  player: {}
}))

const VideoPlayer = () => {
  const classes = useStyles()
  const currentVideo = useSelector(getCurrentVideo)

  return (
    <div className={classes.root}>
      <ReactPlayer
        className={classes.player}
        width={'100%'}
        height={'100%'}
        url={currentVideo ? currentVideo.src : ''}
        controls
      />
    </div>
  )
}
export default VideoPlayer
