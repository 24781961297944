// import { useCallback, useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import MapCategory from './MapCategory'

import { categoriesSelector } from '../../modules/reducerMap'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.sizes.menuPaddingLeft
  },
  title: {}
}))

const MapMenu = () => {
  const classes = useStyles()
  const categories = useSelector(categoriesSelector)

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Selecciona lo que quieras ver en el mapa:
      </Typography>
      {categories.map((cat) => (
        <MapCategory key={cat.id} id={cat.id} />
      ))}
      {categories.map((cat) => (
        <MapCategory key={cat.id} id={cat.id} />
      ))}
    </div>
  )
}
export default MapMenu
