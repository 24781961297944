import { combineReducers } from 'redux'
import reducerMain from './reducerMain'
import reducerMap from './reducerMap'
import reducerTranslate from './reducerTranslate'
import reducerHouses from './reducerHouses'
import reducerHousesFilter from './reducerHousesFilter'
import reducerHousesTable from './reducerHousesTable'
import reducerPDFViewer from './reducerPDFViewer'
import reducerDocuments from './reducerDocuments'
import reducerGallery from './reducerGallery'
import reducerVideos from './reducerVideos'
import reducerRotator from './reducerRotator'
import reducerRotatorGroups from './reducerRotatorGroups'
import reducerGeneralView from './reducerGeneralView'
import reducerRead from './reducerRead'
import reducerPromocion from './reducerPromocion'

export default combineReducers({
  main: reducerMain,
  translate: reducerTranslate,
  map: reducerMap,
  houses: reducerHouses,
  filter: reducerHousesFilter,
  housesTable: reducerHousesTable,
  pdfViewer: reducerPDFViewer,
  documents: reducerDocuments,
  gallery: reducerGallery,
  videos: reducerVideos,
  rotator: reducerRotator,
  rotatorGroups: reducerRotatorGroups,
  generalView: reducerGeneralView,
  read: reducerRead,
  promocion: reducerPromocion
})
