import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTransition, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'

import {
  getCurrentGallery,
  currentImageIdxSelector,
  setNextImage,
  setPreviousImage
} from '../../modules/reducerGallery'

const useStyles = makeStyles((theme) => ({
  root: {
    touchAction: 'none',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block'
  }
}))

const GalleryCenter = () => {
  const classes = useStyles()
  const currentGallery = useSelector(getCurrentGallery)
  const imgIdx = useSelector(currentImageIdxSelector)
  const dispatch = useDispatch()

  const imageTransition = useTransition(
    currentGallery && currentGallery.images.length > 0
      ? currentGallery.images[imgIdx].src
      : '',
    (item) => item,
    {
      from: {
        opacity: 0,
        position: 'absolute'
      },
      enter: {
        opacity: 1
      },
      leave: {
        opacity: 0
      },
      config: { duration: 1000 }
    }
  )

  const bind = useDrag(({ movement, cancel, distance, active, dragging }) => {
    if (active && dragging) {
      if (movement[0] < -10) {
        cancel(true)
        dispatch(setNextImage())
      } else if (movement[0] > 10) {
        cancel(true)
        dispatch(setPreviousImage())
      }
    }
  })

  return currentGallery && currentGallery.images.length > 0 ? (
    <div {...bind()} className={classes.root}>
      {imageTransition.map(({ item, props, key }) => (
        <animated.img
          className={classes.image}
          style={props}
          key={key}
          src={item}
          draggable="false"
        ></animated.img>
      ))}
    </div>
  ) : null
}
export default GalleryCenter
