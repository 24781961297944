import { useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import BottomBarButton from '../BottomBar/BottomBarButton'

import {
  rotate,
  ROTATION_SPEED,
  modeSelector,
  setMode,
  MODE,
  stepCountSelector,
  stepSelector,
  loadingSelector
} from '../../modules/reducerRotator'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RotatorPlayButton = (props) => {
  let timerRef = useRef()
  const mode = useSelector(modeSelector)
  const currentStep = useSelector(stepSelector)
  const stepCount = useSelector(stepCountSelector)
  const loading = useSelector(loadingSelector)
  const dispatch = useDispatch()

  const setModeD = useCallback(
    (value) => {
      dispatch(setMode(value))
    },
    [dispatch]
  )

  const rot = useCallback(() => {
    dispatch(rotate(1))
  }, [dispatch])

  useEffect(() => {
    if (mode === MODE.ROT_AUTOMATIC) {
      if (!timerRef.current) {
        timerRef.current = setInterval(rot, ROTATION_SPEED)
      }
    } else {
      clearInterval(timerRef.current)
      timerRef.current = null
    }
    return () => {
      clearInterval(timerRef.current)
    }
  }, [rot, mode])

  useEffect(() => {
    if (currentStep === stepCount - 1) {
      setModeD(MODE.BUTTONS)
    }
  }, [currentStep, stepCount, setModeD])

  const handleClick = useCallback(
    (e) => {
      if (mode === MODE.ROT_AUTOMATIC) {
        setModeD(MODE.BUTTONS)
      } else {
        setModeD(MODE.ROT_AUTOMATIC)
      }
    },
    [mode, setModeD]
  )

  return (
    <BottomBarButton
      {...props}
      tooltip="Autómatico"
      tooltipEnabled={true}
      onClick={handleClick}
      disabled={
        loading ||
        !(
          mode === MODE.BUTTONS ||
          mode === MODE.PAN ||
          mode === MODE.ROT_AUTOMATIC
        ) ||
        currentStep === stepCount - 1
      }
      checked={mode === MODE.ROT_AUTOMATIC}
    >
      {mode === MODE.ROT_AUTOMATIC ? (
        <StopIcon fontSize="inherit" />
      ) : (
        <PlayArrowIcon fontSize="inherit" />
      )}
    </BottomBarButton>
  )
}
export default RotatorPlayButton
