import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexFlow: 'row', alignItems: 'center' },
  button: {
    borderRadius: 50,
    minWidth: 40,
    minHeight: 40,
    '&:hover': { backgroundColor: 'transparent' }
  },
  buttonCurrent: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    '&:hover': { backgroundColor: theme.palette.primary.light }
  },
  pointsWrapper: { width: 24, textAlign: 'center', userSelect: 'none' }
}))

const Paginator = ({ count, current, onChange }) => {
  const classes = useStyles()
  const [buttons, setButtons] = useState([])

  const getArray = (first, last) => {
    const count = last - first + 1
    const array = new Array(count)
    for (let i = 0; i < count; ++i) {
      array[i] = first + i
    }
    return array
  }

  useEffect(() => {
    if (count <= 7) {
      setButtons(getArray(1, count))
    } else {
      if (current < 4) {
        const array = [...getArray(1, 5), '...', count]
        setButtons(array)
      } else if (current > count - 5) {
        const array = [1, '...', ...getArray(count - 4, count)]
        setButtons(array)
      } else {
        const array = [
          1,
          '...',
          ...getArray(current, current + 2),
          '...',
          count
        ]
        setButtons(array)
      }
    }
  }, [count, current])

  const handleChange = (idx) => {
    if (onChange) {
      idx = Math.min(Math.max(0, idx), count - 1)
      if (idx !== current) {
        onChange(idx)
      }
    }
  }

  const getButton = (button, idx) => {
    switch (button) {
      case '...':
        return (
          <div key={idx} className={classes.pointsWrapper}>
            ...
          </div>
        )
      default:
        return (
          <Button
            key={idx}
            color="primary"
            className={`${classes.button} ${
              button === current + 1 ? classes.buttonCurrent : ''
            }`}
            onClick={() => handleChange(button - 1)}
          >
            {button}
          </Button>
        )
    }
  }

  return count > 1 ? (
    <div className={classes.root}>
      <IconButton
        disabled={current === 0}
        color="primary"
        onClick={() => handleChange(current - 1)}
      >
        <NavigateBeforeIcon />
      </IconButton>
      {buttons.map((button, idx) => getButton(button, idx))}
      <IconButton
        disabled={current === count - 1}
        color="primary"
        onClick={() => handleChange(current + 1)}
      >
        <NavigateNextIcon />
      </IconButton>
    </div>
  ) : null
}
export default Paginator
