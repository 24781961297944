import React, { useCallback, useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import useMeasure from 'react-use-measure'
import mergeRefs from 'react-merge-refs'
import { useGesture } from 'react-use-gesture'

import PDFBar from './PDFBar'

import {
  urlSelector,
  currentPageIdxSelector,
  scaleSelector,
  rotationSelector,
  isPortrait,
  setContainerSize,
  pageSizeSelector,
  setPageSize,
  setPageCount,
  setPageIdx,
  pageCountSelector
} from '../../modules/reducerPDFViewer'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.pdf.background,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',

    position: 'relative'
  },

  documentWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex', //Solo este
    overflowY: 'auto',
    overflowX: 'auto'
  },
  document: {
    margin: 'auto',
    cursor: ({ panning }) => (panning ? 'grabbing' : 'grab')
  },
  page: {
    boxShadow: `0px 2px 5px 4px rgba(0,0,0,0.40)`
  },
  bar: { width: '100%', height: '50px' }
}))

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFViewer = ({ mobile }) => {
  const [rootBoundsRef, rootBounds] = useMeasure()
  const rootRef = useRef(null)

  const url = useSelector(urlSelector)
  const currentPageIdx = useSelector(currentPageIdxSelector)
  const scale = useSelector(scaleSelector)
  const rotation = useSelector(rotationSelector)
  const [panning, setPanning] = useState(false)
  const pageSize = useSelector(pageSizeSelector)
  const pageCount = useSelector(pageCountSelector)
  const portrait = useSelector(isPortrait)
  const classes = useStyles({ panning })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setContainerSize({
        width: rootBounds.width - 25, //Para poner un margen con el padre
        height: rootBounds.height - 25
      })
    )
  }, [dispatch, rootBounds.width, rootBounds.height])

  const handlePageLoadSuccess = useCallback(
    (page) => {
      dispatch(
        setPageSize({
          width: page.originalWidth,
          height: page.originalHeight
        })
      )
    },
    [dispatch]
  )

  const handleDocumentLoadSuccess = useCallback(
    (pdf) => {
      dispatch(setPageCount(pdf.numPages))
    },
    [dispatch]
  )

  const handleError = useCallback(
    (pdf) => {
      dispatch(setPageCount(0))
    },
    [dispatch]
  )

  const bind = useGesture({
    onDragStart: () => {
      setPanning(true)
    },
    onDragEnd: () => setPanning(false),
    onDrag: ({ delta }) => {
      if (rootRef.current) {
        rootRef.current.scrollLeft -= delta[0]
        rootRef.current.scrollTop -= delta[1]
      }
    },
    onWheelStart: ({ delta, xy }) => {
      if (delta[1] > 0) {
        if (
          Math.ceil(rootBounds.height) + rootRef.current.scrollTop >=
          rootRef.current.scrollHeight
        ) {
          if (currentPageIdx < pageCount - 1) {
            rootRef.current.scrollTop = 0
          }

          dispatch(setPageIdx(currentPageIdx + 1))
        }
      } else {
        if (rootRef.current.scrollTop === 0) {
          dispatch(setPageIdx(currentPageIdx - 1))
        }
      }
    }
  })

  return (
    <div className={classes.root}>
      <div
        {...bind()}
        className={classes.documentWrapper}
        ref={mergeRefs([rootRef, rootBoundsRef])}
      >
        <Document
          className={classes.document}
          file={url}
          error="Error"
          loading="Loading"
          noData=""
          onLoadSuccess={handleDocumentLoadSuccess}
          onLoadError={handleError}
          onSourceError={handleError}
          externalLinkTarget={'_blank'}
          rotate={rotation}
        >
          {currentPageIdx >= 0 ? (
            <Page
              className={classes.page}
              pageIndex={currentPageIdx}
              width={portrait ? pageSize.width : pageSize.height}
              scale={scale}
              renderAnnotationLayer={true}
              renderTextLayer={false}
              onLoadSuccess={handlePageLoadSuccess}
            />
          ) : null}
        </Document>
      </div>
      {pageCount > 0 ? <PDFBar small={mobile} /> : null}
    </div>
  )
}
export default PDFViewer
