import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import HousesTableHead from './HousesTableHead'
import HousesTableRow from './HousesTableRow'
import Paginator from '../Paginator/Paginator'

import {
  getCurrentPageHouses,
  getPageCount,
  setPage,
  getCurrenPage
} from '../../modules/reducerHousesTable'

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    padding: '12px 4px 4px 12px',
    boxSizing: 'border-box'
  },
  tableRoot: {
    height: '100%'
    // padding: '0 32px 32px 32px',
    // boxSizing: 'border-box'
  },
  table: {}
})

export default function HousesTable() {
  const classes = useStyles()
  const currentPageHouses = useSelector(getCurrentPageHouses)
  const currentPage = useSelector(getCurrenPage)
  const pageCount = useSelector(getPageCount)
  const dispatch = useDispatch()

  const handleChangePage = useCallback(
    (newPage) => {
      dispatch(setPage(newPage))
    },
    [dispatch]
  )

  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableRoot}>
        <Table className={classes.table}>
          <HousesTableHead />
          <TableBody>
            {currentPageHouses.map((house, idx) => (
              <HousesTableRow key={house.id} row={idx} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginator
        count={pageCount}
        current={currentPage}
        onChange={handleChangePage}
      />
    </div>
  )
}
