import { createSelector } from 'reselect'
import {
  ROTATOR_SET_N_STEPS,
  ROTATOR_SET_IMAGE_SIZE,
  stepSelector
} from './reducerRotator'
import { findById } from '../utils/utils'

import { parcelaGeneral } from '../rotatorData/parcelaGeneral'

export const ROTATOR_SET_GROUP = 'ROTATOR_SET_GROUP'

const initialState = {
  currentGroup: '',
  groups: [parcelaGeneral]
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case ROTATOR_SET_GROUP: {
      return {
        ...state,
        currentGroup: action.id
      }
    }
    default:
      return state
  }
}
export default changeState

export const setGroup = (groupId) => (dispatch, getState) => {
  const state = getState()
  const groups = groupsSelector(state)
  const group = findById(groups, groupId)
  if (group) {
    dispatch({
      type: ROTATOR_SET_GROUP,
      id: groupId,
      stepCount: group.images.length
    })
    dispatch({
      type: ROTATOR_SET_N_STEPS,
      nSteps: group.images.length
    })
    dispatch({
      type: ROTATOR_SET_IMAGE_SIZE,
      size: group.imageSize
    })
  }
}

export const currentGroupSelector = (state) => state.rotatorGroups.currentGroup
export const groupsSelector = (state) => state.rotatorGroups.groups

export const getGroup = createSelector(
  [groupsSelector],
  (groups) => (groupId) => {
    return groups.find((g) => g.id === groupId)
  }
)

export const getGroupById = () =>
  createSelector([groupsSelector, (_, id) => id], (groups, id) => {
    return findById(groups, id)
  })

//Grupo actual
export const getCurrentGroup = createSelector(
  [groupsSelector, currentGroupSelector],
  (groups, currentGroup) => {
    return findById(groups, currentGroup)
  }
)

//Segmento en el grupo y paso actual
export const getSegment = () =>
  createSelector(
    [getCurrentGroup, stepSelector, (_, id) => id],
    (group, step, id) => {
      return group.segments[step].find((segment) => segment.color === id)
    }
  )

const getPoi = (group, step, color, id) => {
  if (!group || step >= group.segments.length || step < 0 || !color || !id) {
    return null
  }
  const segment = group.segments[step].find((s) => s.color === color)
  const pois = segment ? segment.pois : []
  return pois.find((poi) => poi.id === id)
}

//Centroid del segmento en el grupo y paso actuales
export const getCurrentLocalCentroid = createSelector(
  [getCurrentGroup, stepSelector],
  (currentGroup, currentStep) => (color) => {
    const poi = getPoi(currentGroup, currentStep, color, 'centroid')
    return poi ? poi.point : [0, 0]
  }
)

//Centroid del segmento en el grupo y paso
export const getLocalCentroid = createSelector(
  [groupsSelector],
  (groups) => (groupId, step, color) => {
    const poi = getPoi(findById(groups, groupId), step, color, 'centroid')
    return poi ? poi.point : [0, 0]
  }
)
