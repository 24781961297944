import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '18px',
    border: `1px solid ${theme.palette.other.detail}`,
    borderRadius: '24px',
    backgroundColor: '#FFFFFFA0',
    opacity: '0.8',

    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`
    },
    '&:disabled': { backgroundColor: 'rgb(200, 200, 200)' },
    color: ({ checked }) =>
      checked ? theme.palette.secondary.main : theme.palette.primary.main
  }
}))

const BottomBarButton = ({
  checked,
  tooltip,
  tooltipEnabled,
  onClick,
  disabled,
  ...props
}) => {
  const classes = useStyles({ checked: checked })
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <Tooltip
      open={tooltipEnabled && open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={tooltip}
      arrow
    >
      <IconButton
        className={`${props.className} ${classes.button}`}
        onClick={onClick}
        disabled={disabled}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  )
}
export default BottomBarButton
