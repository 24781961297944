import { makeStyles } from '@material-ui/core/styles'
import DescargaIcon from '../Icons/DescargaIcon'
import PlanoSituacionIcon from '../Icons/PlanoSituacionIcon'
import GaleriaIcon from '../Icons/GaleriaIcon'
import PdfIcon from '../Icons/PdfIcon'
import SeleccionarViviendaIcon from '../Icons/SeleccionarViviendaIcon'
import VideoIcon from '../Icons/VideoIcon'
import VisitaVirtualIcon from '../Icons/VisitaVirtualIcon'
import Vista360Icon from '../Icons/Vista360Icon'
import VistaAereaIcon from '../Icons/VistaAereaIcon'
import VistaPeatonalIcon from '../Icons/VistaPeatonalIcon'
import { MENU_OPTIONS } from '../../modules/reducerMain'
const useStyles = makeStyles((theme) => ({
  icon: {
    width: '24px',
    height: '100%',
    fill: 'inherit'
  }
}))

const OptionIcon = ({ optionId }) => {
  const classes = useStyles()

  const getIcon = (menuOptionId) => {
    switch (menuOptionId) {
      case MENU_OPTIONS.VISTA_GENERAL:
        return <Vista360Icon className={classes.icon} />
      case MENU_OPTIONS.PLANO_SITUACION:
        return <PlanoSituacionIcon className={classes.icon} />
      case MENU_OPTIONS.VISTA_AEREA:
        return <VistaAereaIcon className={classes.icon} />
      case MENU_OPTIONS.VISTA_PEATONAL:
        return <VistaPeatonalIcon className={classes.icon} />
      case MENU_OPTIONS.SELECCIONA_VIVIENDA:
        return <SeleccionarViviendaIcon className={classes.icon} />
      case MENU_OPTIONS.VISITAS_VIRTUALES:
        return <VisitaVirtualIcon className={classes.icon} />
      case MENU_OPTIONS.GALERIA_IMAGENES:
        return <GaleriaIcon className={classes.icon} />
      case MENU_OPTIONS.VIDEOS:
        return <VideoIcon className={classes.icon} />
      case MENU_OPTIONS.DESCARGAS:
        return <DescargaIcon className={classes.icon} />
      case MENU_OPTIONS.DOCUMENTOS:
        return <PdfIcon className={classes.icon} />
      default:
        return null
    }
  }
  return getIcon(optionId)
}
export default OptionIcon
