// import { useCallback } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import GalleriesMenu from './GalleriesMenu'

import { getCurrentHouse } from '../../modules/reducerHouses'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const HouseGalleriesMenu = () => {
  const house = useSelector(getCurrentHouse)
  // const dispatch = useDispatch()
  // const classes = useStyles()
  return house ? <GalleriesMenu galleriesIds={[house.gallery]} /> : null
}
export default HouseGalleriesMenu
