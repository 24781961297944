import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import {
  columnsSelector,
  sortOrderSelector,
  sortColumnSelector,
  columnClicked
} from '../../modules/reducerHousesTable'

const useStyles = makeStyles({
  tableHead: { textTransform: 'uppercase', height: 40 },
  sortLabel: { flexDirection: 'revert' },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  cell: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    padding: 0,
    textAlign: 'center'
  }
})

const HousesTableHead = () => {
  const classes = useStyles()
  const columns = useSelector(columnsSelector)
  const order = useSelector(sortOrderSelector)
  const sortColumn = useSelector(sortColumnSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (colId) => {
      dispatch(columnClicked(colId))
    },
    [dispatch]
  )

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {columns.map((col) => (
          <TableCell
            key={col.id}
            className={classes.cell}
            align={col.align}
            padding={col.padding}
            sortDirection={sortColumn === col.id ? order : false}
          >
            {col.sortEnabled ? (
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === col.id}
                direction={sortColumn === col.id ? order : 'asc'}
                IconComponent={ArrowDropUpIcon}
                onClick={() => handleClick(col.id)}
              >
                {col.label}
                {sortColumn === col.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
export default HousesTableHead
