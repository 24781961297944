import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    margin: 0,
    color: theme.palette.primary.contrastText,
    '&:hover': { color: theme.palette.secondary.main }
  },
  label: {
    fontSize: theme.typography.h4.fontSize,
    paddingLeft: 0
  },
  radio: {
    color: 'inherit',
    padding: 4
  }
}))

const LabelRadio = ({ label, value, ...props }) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      classes={{ root: classes.labelRoot, label: classes.label }}
      value={value}
      control={
        <Radio
          classes={{ root: classes.radio }}
          icon={<RadioButtonUncheckedIcon style={{ fontSize: 15 }} />}
          checkedIcon={<RadioButtonCheckedIcon style={{ fontSize: 15 }} />}
        />
      }
      label={label}
      {...props}
    />
  )
}
export default LabelRadio
