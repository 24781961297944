const DescargaIcon = (props) => {
  return (
    <svg viewBox="0 0 28 21" {...props}>
      <path
        fill="fill"
        d="M26.918 11.693c-.483-3.023-3.777-3.738-5.786-3.738 0-1.035-.08-3.819-1.929-5.728C17.918.795 15.99.08 13.498 0c-2.491 0-4.42.716-5.705 2.227-1.849 1.91-2.01 4.614-1.929 5.728-2.009 0-5.223.636-5.786 3.738-.24 1.511.08 2.864.884 3.818C2.65 17.5 5.864 17.5 6.025 17.5c.24 0 .402-.16.402-.398 0-.159-.161-.398-.402-.398 0 0-2.973-.079-4.42-1.75-.723-.795-.884-1.909-.723-3.181.562-3.341 5.143-3.023 5.384-3.023.16 0 .24 0 .321-.08.08-.08.16-.238.08-.318 0 0-.32-3.42 1.688-5.568C9.56 1.432 11.248.795 13.498.795s4.018.637 5.143 1.91c2.009 2.227 1.687 5.568 1.687 5.568 0 .08 0 .238.08.318.081.08.161.159.322.08.08 0 4.822-.398 5.384 3.022.241 1.273 0 2.386-.723 3.182-1.366 1.83-4.42 1.83-4.42 1.83-.24 0-.402.159-.402.397 0 .239.161.398.402.398.16 0 3.375-.08 5.063-2.068.884-.955 1.125-2.228.884-3.739z"
      />
      <path
        fill="fill"
        d="M17.194 15.511L14.3 19.41v-9.068c0-.239-.16-.398-.402-.398-.24 0-.401.16-.401.398v9.068l-2.893-3.898c-.161-.159-.402-.238-.563-.08-.16.16-.241.399-.08.558l3.616 4.931c.08.08.08.08.16.08h.322c.08 0 .16-.08.16-.16l3.617-4.93c.08-.08.08-.16.08-.24 0-.159-.08-.238-.16-.318-.242-.08-.483-.08-.563.16z"
      />
    </svg>
  )
}
export default DescargaIcon
