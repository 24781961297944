import { useCallback, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import DescargaIcon from '../Icons/DescargaIcon'
import {
  getDocuments,
  addDownload,
  downloadSelector
} from '../../modules/reducerDocuments'
import { setUrl, urlSelector } from '../../modules/reducerPDFViewer'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    paddingTop: 0
  },
  listItem: {
    display: 'flex',
    flexFlow: 'row',
    cursor: 'pointer',
    margin: '8px 0px',
    height: '20px', //Hace un efecto raro cuando se expande si so se pone
    color: ({ selected }) => (selected ? theme.palette.secondary.main : ''),
    '&:hover': { color: theme.palette.secondary.main }
  },
  itemText: {
    overflow: 'hidden'
  },
  itemIcon: { minWidth: 0, paddingRight: 4, color: 'inherit' },
  iconButton: {
    color: ({ selected }) =>
      selected ? theme.palette.secondary.main : 'inherit',
    fill: ({ selected }) =>
      selected
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText
  }
}))

const DocItem = ({ id, name, url }) => {
  const [hover, setHover] = useState(false)
  const currentUrl = useSelector(urlSelector)
  const selected = url === currentUrl || hover
  const classes = useStyles({ selected })
  const downloadDocs = useSelector(downloadSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (doc) => {
      dispatch(setUrl(url))
    },
    [dispatch, url]
  )

  const handleDownloadClick = useCallback(
    (doc) => {
      dispatch(addDownload(id))
    },
    [dispatch, id]
  )

  const handleMouseOver = useCallback(() => {
    setHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  return (
    <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <ListItem
        className={classes.listItem}
        role={undefined}
        dense
        disableGutters
        onClick={handleClick}
      >
        <ListItemIcon className={classes.itemIcon}>
          <FiberManualRecordIcon disableRipple style={{ fontSize: 12 }} />
        </ListItemIcon>
        <ListItemText
          className={classes.itemText}
          primary={name}
          primaryTypographyProps={{ variant: 'h3' }}
        />
        {downloadDocs.indexOf(id) < 0 ? (
          <ListItemSecondaryAction>
            <Tooltip title={'Enviar a descargas'} arrow>
              <IconButton
                edge="end"
                className={classes.iconButton}
                onClick={handleDownloadClick}
              >
                <DescargaIcon width={20} height={24} fill={'inherit'} />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    </div>
  )
}

const DocumentsMenu = ({ docIds }) => {
  const classes = useStyles()
  const gDocuments = useSelector(getDocuments)
  const documents = gDocuments(docIds)
  const dispatch = useDispatch()

  useEffect(() => {
    if (documents && documents.length > 0) {
      dispatch(setUrl(documents[0].src))
    }
  }, [dispatch, documents])

  return documents && documents.length ? (
    <>
      <List className={classes.root}>
        {documents.map((doc, idx) => (
          <DocItem key={idx} id={doc.id} name={doc.name} url={doc.src} />
        ))}
      </List>
    </>
  ) : null
}

export default DocumentsMenu
