// import { useCallback, useState, useRef, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import MainMenu from './MainMenu'
import Submenu from './Submenu'

import { currentSubmenuSelector } from '../../modules/reducerMain'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const Menu = () => {
  // const classes = useStyles()
  const currentSubmenu = useSelector(currentSubmenuSelector)

  return <>{currentSubmenu !== '' ? <Submenu /> : <MainMenu />}</>
}
export default Menu
