import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import {
  getCurrentGallery,
  currentImageIdxSelector,
  setNextImage,
  setPreviousImage
} from '../../modules/reducerGallery'

import GalleryCenter from './GalleryCenter'
import GalleryButton from '../Common/GalleryButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: theme.palette.gallery.background,
    position: 'relative'
  },
  buttonPrev: { position: 'absolue', left: 10, top: '50%' }
}))

const Gallery = () => {
  const classes = useStyles()
  const imgIdx = useSelector(currentImageIdxSelector)
  const currentGallery = useSelector(getCurrentGallery)
  const dispatch = useDispatch()

  const handleNextClick = useCallback(() => {
    dispatch(setNextImage())
  }, [dispatch])

  const handleBackClick = useCallback(() => {
    dispatch(setPreviousImage())
  }, [dispatch])

  return currentGallery ? (
    <div className={classes.root}>
      <GalleryCenter />
      <GalleryButton
        next={true}
        absolute
        count={currentGallery.images.length}
        currentIdx={imgIdx}
        onClick={handleNextClick}
      />
      <GalleryButton
        absolute
        count={currentGallery.images.length}
        currentIdx={imgIdx}
        onClick={handleBackClick}
      />
    </div>
  ) : null
}
export default Gallery
/*
<GalleryPreviousButton
  positioning={{
    position: 'absolute',
    left: 10,
    top: '50%',
    transform: 'translateY(-50%)'
  }}
/>
<GalleryNextButton
  positioning={{
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)'
  }}
/>
*/
