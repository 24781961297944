const VideoIcon = (props) => {
  return (
    <svg viewBox="0 0 22 19" {...props}>
      <path
        fill="fill"
        d="M21.682 0H.318C.148 0 0 .128 0 .274v18.261c0 .147.148.274.318.274h21.364c.17 0 .318-.127.318-.274V.274C22 .128 21.873 0 21.682 0zM2.032 10.555H.636v-3.05h1.398v3.05zm0-3.597H.636v-3.05h1.398v3.05zM.636 11.103h1.398v3.05H.635v-3.05zM2.668.548h16.495v17.714H2.668V.548zm17.151 6.958h1.567v3.05h-1.567v-3.05zm1.546-.548h-1.567v-3.05h1.567v3.05zm-1.546 4.145h1.567v3.05h-1.567v-3.05zm1.546-7.743h-1.567V.548h1.567V3.36zM2.033.548V3.36H.635V.548h1.398zM.635 14.682h1.398v3.58H.635v-3.58zm19.184 3.58v-3.58h1.567v3.58h-1.567z"
      />
      <path
        fill="fill"
        d="M15.267 9.003L8.724 5.825a.332.332 0 00-.318 0 .26.26 0 00-.148.238v6.373c0 .091.063.183.148.238a.456.456 0 00.17.036.336.336 0 00.148-.037l6.543-3.195c.106-.055.169-.146.169-.238 0-.091-.063-.182-.17-.237zM8.872 11.96V6.52l5.59 2.721-5.59 2.721z"
      />
    </svg>
  )
}
export default VideoIcon
