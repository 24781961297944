import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import MapSubcategory from './MapSubcategory'
import { getCategory } from '../../modules/reducerMap'

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // height: '45px',
    display: 'flex',
    flexFlow: 'column'
  },
  title: {
    color: theme.palette.primary.contrastText,
    margin: '12px 0px 0px 0px'
  }
}))

const MapCategory = ({ id }) => {
  const classes = useStyles()
  const gCategory = useMemo(getCategory, [])
  const category = useSelector((state) => gCategory(state, id))

  return category ? (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {category.name}
      </Typography>
      {category.subcategories.map((subId) => {
        return <MapSubcategory key={subId} id={subId} />
      })}
    </div>
  ) : null
}
export default MapCategory
