import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import LasLomasTheme from './LasLomasTheme'
import Header from './Header/Header'
import Lateral from './Lateral/Lateral'
import Center from './Center/Center'
import useMeasure from 'react-use-measure'

import { setRootSize } from '../modules/reducerMain'
import { readAllData } from '../modules/reducerRead'
import {
  currentPromocionSelector,
  promocionesSelector,
  setPromocionByName
} from '../modules/reducerPromocion'

const useStyles = makeStyles((theme) => ({
  root: { width: '100vw', height: '100vh', display: 'flex' },
  centerWrapper: {
    backgroundColor: 'transparent',
    paddingTop: LasLomasTheme.sizes.toolbar.height,
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'row',
    position: 'relative'
  }
}))

const LasLomasMain = () => {
  const classes = useStyles()
  const [rootBoundsRef, rootBounds] = useMeasure()
  const currentPromocion = useSelector(currentPromocionSelector)
  const promociones = useSelector(promocionesSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(readAllData())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      setRootSize({ width: rootBounds.width, height: rootBounds.height })
    )
  }, [dispatch, rootBounds])

  useEffect(() => {
    if (currentPromocion === '' && promociones.length > 0) {
      dispatch(setPromocionByName('Las Lomas del Higuerón'))
    }
  }, [dispatch, promociones, currentPromocion])

  return (
    <ThemeProvider theme={LasLomasTheme}>
      <div ref={rootBoundsRef} className={classes.root}>
        <Header />
        <div className={classes.centerWrapper}>
          <Lateral />
          <Center />
        </div>
      </div>
    </ThemeProvider>
  )
}
export default LasLomasMain
