// import { createSelector } from 'reselect'

export const SET_DATA = 'SET_DATA'
export const PUSH_DATA = 'PUSH_DATA'

export const DATA_TYPE = {
  PROMOCION: 'PR',
  MEDIA: 'ME',
  GALLERY: 'GA',
  MAP_CATEGORIES: 'MC',
  POINT_OF_INTEREST: 'POI',
  HOUSE: 'H',
  HOUSE_STATE: 'HS',
  HOUSE_TYPE: 'HT',
  HOUSE_FLOOR: 'HF',
  ORIENTATION: 'OR'
}
export const MEDIA_TYPE = {
  PDF: 'PDF',
  VIDEO: 'V'
}

// const baseUrl = 'http://localhost/wordpress/wp-json/wp/v2/'
const baseUrl = 'http://laslomasdelhigueron.atwebpages.com/wp-json/wp/v2/'

const ROWS_PER_PAGE = 100

const initialState = {
  data: [
    {
      id: DATA_TYPE.PROMOCION,
      name: 'promocion',
      totalCount: 0,
      data: []
    },
    {
      id: DATA_TYPE.GALLERY,
      name: 'galeria',
      totalCount: 0,
      data: []
    },
    {
      id: DATA_TYPE.MEDIA,
      name: 'media',
      totalCount: 0,
      data: []
    },
    {
      id: DATA_TYPE.MAP_CATEGORIES,
      name: 'tipo_punto_interes',
      totalCount: 0,
      data: []
    },
    {
      id: DATA_TYPE.POINT_OF_INTEREST,
      name: 'punto_de_interes',
      totalCount: 0,
      data: []
    },
    {
      id: DATA_TYPE.HOUSE_STATE,
      name: 'estado_vivienda',
      totalCount: 0,
      data: []
    },
    {
      id: DATA_TYPE.HOUSE_TYPE,
      name: 'tipo_vivienda',
      totalCount: 0,
      data: []
    },
    {
      id: DATA_TYPE.HOUSE_FLOOR,
      name: 'planta',
      totalCount: 0,
      data: []
    },
    {
      id: DATA_TYPE.HOUSE,
      name: 'vivienda',
      totalCount: 0,
      data: []
    },
    {
      id: DATA_TYPE.ORIENTATION,
      name: 'orientacion',
      totalCount: 0,
      data: []
    }
    // {
    //   id: DATA_TYPE.DOCUMENT,
    //   name: 'documento',
    //   totalCount: 0,
    //   data: []
    // }
  ]
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_DATA: {
      const data = state.data.map((d) => {
        if (d.id === action.typeId) {
          return { ...d, totalCount: action.data.length, data: action.data }
        } else {
          return d
        }
      })
      return { ...state, data }
    }
    case PUSH_DATA: {
      const data = state.data.map((d) => {
        if (d.id === action.typeId) {
          return {
            ...d,
            totalCount: action.total,
            data: [...d.data, ...action.data]
          }
        } else {
          return d
        }
      })
      return { ...state, data }
    }
    default:
      return state
  }
}
export default changeState

export const setData = (typeId, data) => ({
  type: SET_DATA,
  typeId,
  data
})

export const pushData = (typeId, data, total) => ({
  type: SET_DATA,
  typeId,
  data,
  total
})

export const readAllData = () => (dispatch, getState) => {
  const state = getState()
  const data = dataSelector(state)

  async function readPage(dataType, page) {
    if (!dataType) {
      return
    }
    const response = await fetch(
      `${baseUrl}${dataType.name}/?per_page=${ROWS_PER_PAGE}&page=${page}`
    )
    if (!response.ok) {
      // oups! something went wrong
      return
    }

    const posts = await response.json()
    //   response.headers.get('x-wp-total'),
    //   response.headers.get('x-wp-totalpages')
    const totalRows = response.headers.get('x-wp-total')
    const maxRowsRead = page * ROWS_PER_PAGE
    dispatch({
      type: PUSH_DATA,
      typeId: dataType.id,
      data: posts,
      total: parseInt(totalRows)
    })
    if (maxRowsRead < totalRows) {
      readPage(dataType, page + 1)
    }
  }
  //
  for (let dataType of data) {
    readPage(dataType, 1)
  }
}

export const dataSelector = (state) => state.read.data
