import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import RadioGroup from '@material-ui/core/RadioGroup'
import LabelRadio from '../Common/LabelRadio'
import Title from './Title'

import { houseFloorsSelector } from '../../modules/reducerHouses'
import {
  floorSelector,
  setFloor,
  getClassifyHousesByFloor
} from '../../modules/reducerHousesFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // height: '45px',
    display: 'flex',
    flexFlow: 'column'
  }
}))

const FloorItem = ({ id, name }) => {
  const housesByFloor = useSelector(getClassifyHousesByFloor)
  const fl = housesByFloor.find((v) => v.floorId === id)

  return (
    <LabelRadio
      key={id}
      label={name + ` (${fl ? fl.housesId.length : 0})`}
      value={id}
    />
  )
}

const Floor = ({ id }) => {
  const classes = useStyles()
  const floors = useSelector(houseFloorsSelector)
  const floorSelected = useSelector(floorSelector)

  const dispatch = useDispatch()

  const handleChange = useCallback(
    (e) => {
      if (e.target.value) {
        if (e.target.value === floorSelected) {
          dispatch(setFloor(''))
        } else {
          dispatch(setFloor(e.target.value))
        }
      }
    },
    [dispatch, floorSelected]
  )

  return floors.length > 0 ? (
    <div className={classes.root}>
      <Title text={'Plantas'} />
      <RadioGroup value={floorSelected} onClick={handleChange}>
        {floors.map((floor) => (
          <FloorItem key={floor.id} id={floor.id} name={floor.name} />
        ))}
      </RadioGroup>
    </div>
  ) : null
}
export default Floor
