import { createSelector } from 'reselect'
import { getCurrentPromocion } from './reducerPromocion'
import { getFilterHouses } from './reducerHousesFilter'
import {
  houseTypologiesSelector,
  houseOrientationSelector,
  favoritesSelector
} from './reducerHouses'
import { findById } from '../utils/utils'

export const SET_SORT_ORDER = 'SET_SORT_ORDER'
export const SET_SORT_COLUMN = 'SET_SORT_COLUMN'
export const COLUMN_CLICKED = 'COLUMN_CLICKED'
export const SET_PAGE = 'SET_PAGE'
export const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE'

export const COLUMNS = {
  NAME: 'c0',
  TYPOLOGY: 'c1',
  AREA: 'c2',
  EXTRA_SPACE: 'c3',
  ORIENTATION: 'c4',
  PRICE: 'c5',
  FAVORITE: 'c6',
  BUTTON: 'c7'
}
const initialState = {
  columns: [
    { id: COLUMNS.NAME, label: 'Nombre', align: 'left', sortEnabled: true },
    {
      id: COLUMNS.TYPOLOGY,
      label: 'Tipología',
      align: 'center',
      sortEnabled: true
    },
    {
      id: COLUMNS.AREA,
      label: 'Superficie',
      align: 'center',
      sortEnabled: true
    },
    {
      id: COLUMNS.EXTRA_SPACE,
      label: 'Espacio adicional',
      align: 'center',
      sortEnabled: true
    },
    {
      id: COLUMNS.ORIENTATION,
      label: 'Orientacion',
      align: 'center',
      sortEnabled: true
    },
    { id: COLUMNS.PRICE, label: 'Precio', align: 'center', sortEnabled: true },
    { id: COLUMNS.FAVORITE, label: '', align: 'center', sortEnabled: false },
    { id: COLUMNS.BUTTON, label: '', align: 'center', sortEnabled: false }
  ],
  sortOrder: 'asc',
  sortColumn: '',
  rowsPerPage: 10,
  currentPage: 0
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case COLUMN_CLICKED:
      let sortOrder = state.sortOrder
      let sortColumn = state.sortColumn
      if (sortColumn !== action.col) {
        sortColumn = action.col
      } else {
        sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
      }
      return { ...state, sortOrder, sortColumn }
    case SET_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.order
      }
    case SET_SORT_COLUMN:
      return {
        ...state,
        sortColumn: action.column
      }
    case SET_PAGE:
      return {
        ...state,
        currentPage: action.page
      }
    case SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.nRows
      }
    default:
      return state
  }
}
export default changeState

export const columnClicked = (col) => ({
  type: COLUMN_CLICKED,
  col
})

export const setSortOrder = (order) => ({
  type: SET_SORT_ORDER,
  order
})

export const setSorColumn = (column) => ({
  type: SET_SORT_COLUMN,
  column
})

export const setPage = (page) => ({
  type: SET_PAGE,
  page
})

export const setRowsPerPage = (nRows) => ({
  type: SET_ROWS_PER_PAGE,
  nRows
})

export const columnsSelector = (state) => state.housesTable.columns
export const sortOrderSelector = (state) => state.housesTable.sortOrder
export const sortColumnSelector = (state) => state.housesTable.sortColumn
export const rowsPerPageSelector = (state) => state.housesTable.rowsPerPage
//Usar getCurrenPage en lugar de currentPageSelector
const currentPageSelector = (state) => state.housesTable.currentPage

export const getRowCount = createSelector([getFilterHouses], (houses) => {
  return houses.length
})

/*
  Crea datos de cada columna. El valor que se de es el que se utiliza para
  ordenar
*/
export const getTableHouses = createSelector(
  [
    getFilterHouses,
    houseTypologiesSelector,
    houseOrientationSelector,
    favoritesSelector
  ],
  (houses, houseTypologies, houseOrientation, favorites) => {
    return houses.map((house) => {
      const o = findById(houseOrientation, house.orientation)
      const typo = findById(houseTypologies, house.typology)
      return {
        id: house.id,
        state: house.state,
        favorite: favorites.has(house.id),
        c0: house.name,
        c1: typo ? typo.name : 'Error',
        c2: typo ? typo.area : 'Error',
        c3: typo ? typo.aditionalArea : 'Error',
        c4: o ? o.name : 'Error',
        c5: house.price
      }
    })
  }
)

const sortByColumn = (column, order) => {
  let sortOrder = order === 'asc' ? -1 : 1
  return (a, b) => {
    var result = a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0
    return result * sortOrder
  }
}

export const getSortedHouses = createSelector(
  [getTableHouses, sortOrderSelector, sortColumnSelector],
  (houses, order, column) => {
    if (order === '' || column === '') {
      return houses
    }
    const newHouses = [...houses]
    return newHouses.sort(sortByColumn(column, order))
  }
)

export const getPageCount = createSelector(
  [getFilterHouses, rowsPerPageSelector],
  (houses, rowsPerPage) => {
    const n = houses.length
    return Math.ceil(n / rowsPerPage)
  }
)

export const getCurrenPage = createSelector(
  [currentPageSelector, getPageCount],
  (currentPage, pageCount) => {
    return Math.min(currentPage, pageCount - 1)
  }
)

export const getHousesOfPage = createSelector(
  [getSortedHouses, rowsPerPageSelector, getPageCount, getCurrentPromocion],
  (houses, rowsPerPage, pageCount, promocion) => (nPage) => {
    if (nPage >= pageCount) {
      return []
    }
    const pageHouses = houses.slice(
      nPage * rowsPerPage,
      nPage * rowsPerPage + rowsPerPage
    )
    //Termina de crear los datos
    const currency = promocion ? promocion.currency : ''
    const areaUnit = promocion ? promocion.areaUnit : ''
    return pageHouses.map((house) => {
      return {
        ...house,
        c2: house.c2 + ' ' + areaUnit,
        c3: house.c3 + ' ' + areaUnit,
        c5: house.c5 + currency
      }
    })
  }
)

export const getCurrentPageHouses = createSelector(
  [getHousesOfPage, getCurrenPage],
  (gHousesOfPage, currentPage) => {
    return gHousesOfPage(currentPage)
  }
)
