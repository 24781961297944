import { useState, useCallback, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import useSound from 'use-sound'
import SegmentsCanvas from '../SegmentsCanvas/SegmentsCanvas'
import SegmentTooltip from '../SegmentsCanvas/SegmentTooltip'
import GeneralViewTooltipContent from './GeneralViewTooltipContent'
import Rotator from '../Rotator/Rotator'
import SegmentHotspot from '../SegmentsCanvas/SegmentHotspot'
import { drawGeneralViewSegment } from '../../utils/draw'
import {
  toggleSegmentSelection,
  mouseOverSegment,
  hoverSegmentSelector,
  imagesSelector,
  setImages
  // getHoverSegmentCentroid
} from '../../modules/reducerGeneralView'
import {
  stepSelector,
  scaleSelector,
  setTranslation,
  getTranslation,
  setChildFitMode,
  CHILD_FIT_MODE,
  setLoadingContent,
  setEnabled,
  enabledSelector
} from '../../modules/reducerRotator'
import { getCurrentGroup, setGroup } from '../../modules/reducerRotatorGroups'
import { getMobileMode } from '../../modules/reducerMain'
import ring from '../../sounds/420506__jfrecords__uprising1.wav'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  }
}))

const GENERAL_VIEW_GROUP = 'LLDH_0'

const GeneralView = () => {
  const rootRef = useRef(null)
  const [canvasBounds, setCanvasBounds] = useState(null)
  // const [images, setImages] = useState([])
  const images = useSelector(imagesSelector)
  const scale = useSelector(scaleSelector)
  const translation = useSelector(getTranslation)
  const currentStep = useSelector(stepSelector)
  const currentGroup = useSelector(getCurrentGroup)
  const hoverSegmentId = useSelector(hoverSegmentSelector)
  const rotatorEnabled = useSelector(enabledSelector)
  const small = useSelector(getMobileMode)
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [play] = useSound(ring)

  useEffect(() => {
    dispatch(setGroup(GENERAL_VIEW_GROUP))
    dispatch(setChildFitMode(CHILD_FIT_MODE.COVER))
    dispatch(setTranslation({ x: 0, y: 0 }))
  }, [dispatch])

  const loadImages = (images) => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          resolve(img)
        }
        img.onerror = img.onabort = () => {
          reject(src)
        }
        img.src = src
      })
    }
    const promises = []
    for (let i = 0; i < images.length; i++) {
      promises.push(loadImage(images[i]))
    }
    return Promise.all(promises)
  }

  useEffect(() => {
    if (currentGroup && currentGroup.id === GENERAL_VIEW_GROUP) {
      if (images.length === 0 && currentGroup.images.length > 0) {
        dispatch(setLoadingContent(true))
        loadImages([currentGroup.images[0]]).then(
          (imgs) => {
            loadImages(currentGroup.images).then(
              (imgs) => {
                dispatch(setLoadingContent(false))
                dispatch(setImages(imgs))
              },
              (errImg) => {}
            )
          },
          (errImg) => {}
        )
        // loadImages(currentGroup.images).then(
        //   (imgs) => {
        //     dispatch(setLoadingContent(false))
        //     dispatch(setImages(imgs))
        //   },
        //   (errImg) => {}
        // )
      }
    }
  }, [dispatch, currentGroup, images])

  const handleBoundsChanged = useCallback((bounds) => {
    setCanvasBounds(bounds)
  }, [])

  const handleDrawSegment = useCallback(
    (ctx, segment) => {
      if (hoverSegmentId === segment.color) {
        // console.log(segment)
        drawGeneralViewSegment(
          ctx,
          segment,
          '',
          false,
          theme.palette.primary.mainTransparent,
          theme.palette.secondary.main
        )
      }
    },
    // },
    [theme, hoverSegmentId]
  )

  const handleSegmentClick = useCallback(
    (segmentId) => {
      dispatch(toggleSegmentSelection(segmentId))
      dispatch(mouseOverSegment(segmentId))
    },
    [dispatch]
  )

  const handleSegmentHoverChanged = useCallback(
    (segmentId) => {
      dispatch(mouseOverSegment(segmentId))
      if (segmentId) {
        play()
      }
    },
    [dispatch, play]
  )

  const handleDrawStart = useCallback(() => {
    dispatch(setEnabled(false))
  }, [dispatch])

  const handleDrawEnd = useCallback(() => {
    dispatch(setEnabled(true))
  }, [dispatch])

  return currentGroup ? (
    <div ref={rootRef} className={classes.root}>
      <Rotator small={small}>
        <SegmentsCanvas
          scale={scale}
          translation={translation}
          imageSrc={currentGroup.images[currentStep]}
          imageSize={currentGroup.imageSize}
          segments={currentGroup.segments[currentStep]}
          drawSegment={handleDrawSegment}
          onBoundsChanged={handleBoundsChanged}
          onSegmentClick={handleSegmentClick}
          onSegmentHoverChanged={handleSegmentHoverChanged}
          onDrawStart={handleDrawStart}
          onDrawEnd={handleDrawEnd}
        />
      </Rotator>
      <SegmentTooltip
        parentRef={rootRef}
        open={hoverSegmentId !== ''}
        scale={scale}
        translation={translation}
        segmentId={hoverSegmentId}
        poiId={'centroid'}
        childSize={currentGroup.imageSize}
        parentBounds={canvasBounds}
      >
        <GeneralViewTooltipContent segmentId={hoverSegmentId} />
      </SegmentTooltip>
    </div>
  ) : null
}
export default GeneralView
//
/*
{currentGroup.segments[currentStep].map((segment) => (
  <SegmentHotspot
    scale={scale}
    translation={translation}
    update={rotatorEnabled}
    segmentId={segment.color}
    poiId={'centroid'}
    childSize={currentGroup.imageSize}
    parentBounds={canvasBounds}
  >
    <GeneralViewTooltipContent segmentId={segment.color} />
  </SegmentHotspot>
))}
*/
