import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Rotator from '../Rotator/Rotator'

import SegmentsCanvas from '../SegmentsCanvas/SegmentsCanvas'
import {
  stepSelector,
  scaleSelector,
  getTranslation
} from '../../modules/reducerRotator'
import { getCurrentGroup } from '../../modules/reducerRotatorGroups'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: theme.palette.other.backgroundColor,

    position: 'relative'
  }
}))

const HousesRotator = () => {
  const scale = useSelector(scaleSelector)
  const translation = useSelector(getTranslation)
  const currentGroup = useSelector(getCurrentGroup)
  const currentStep = useSelector(stepSelector)
  const classes = useStyles()
  // const dispatch = useDispatch()

  return (
    <div className={classes.root}>
      {currentGroup ? (
        <Rotator>
          <SegmentsCanvas
            scale={scale}
            translation={translation}
            imageSrc={currentGroup.images[currentStep]}
            segments={currentGroup.segments[currentStep]}
          />
        </Rotator>
      ) : null}
    </div>
  )
}
export default HousesRotator
