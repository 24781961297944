import { useCallback, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import ListMenu from '../Common/ListMenu'

import { getCurrentPromocionVideos } from '../../modules/reducerPromocion'
import { setVideo, getCurrentVideo } from '../../modules/reducerVideos'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const VideosMenu = () => {
  // const classes = useStyles()
  const videos = useSelector(getCurrentPromocionVideos)
  const currentVideo = useSelector(getCurrentVideo)
  const dispatch = useDispatch()

  useEffect(() => {
    if (videos.length > 0) {
      dispatch(setVideo(videos[0].id))
    }
  }, [dispatch, videos])

  const handleClick = useCallback(
    (item) => {
      dispatch(setVideo(item.id))
    },
    [dispatch]
  )

  return (
    <ListMenu
      paddingLeft={40}
      items={videos}
      selected={currentVideo ? [currentVideo.id] : []}
      onClick={handleClick}
    />
  )
}
export default VideosMenu
