const HomeIcon = (props) => {
  return (
    <svg viewBox="0 0 19 18" {...props}>
      <path
        fill="fill"
        d="M16.195 10.24c-.224 0-.373.147-.373.369v6.652h-3.735v-4.804c0-.222-.15-.37-.373-.37H7.979c-.224 0-.373.148-.373.37v4.804H3.87v-7.022c0-.222-.149-.37-.373-.37s-.373.148-.373.37v7.391c0 .222.149.37.373.37h4.481c.224 0 .374-.148.374-.37v-4.804h2.987v4.804c0 .222.15.37.374.37h4.481c.224 0 .373-.148.373-.37v-7.02c0-.222-.149-.37-.373-.37z"
      />
      <path
        fill="fill"
        d="M18.734 9.574l-8.589-8.5a.364.364 0 00-.523 0l-8.664 8.5a.355.355 0 000 .517c.15.148.374.148.523 0l8.365-8.204 8.29 8.204c.076.074.15.074.3.074.149 0 .224 0 .298-.074.075-.074.075-.37 0-.517zm-5.9-7.096h2.24v2.218c0 .221.15.37.374.37s.373-.149.373-.37V2.109c0-.222-.149-.37-.373-.37h-2.614c-.224 0-.374.148-.374.37 0 .221.15.37.374.37z"
      />
    </svg>
  )
}
export default HomeIcon
