import { createSelector } from 'reselect'
import { dataSelector, DATA_TYPE } from './reducerRead'
import { findById } from '../utils/utils'

export const SET_CURRENT_GALLERY = 'SET_CURRENT_GALLERY'
export const SET_CURRENT_IMAGE_IDX = 'SET_CURRENT_IMAGE_IDX'

const initialState = {
  // images: [
  //   {
  //     id: 'i0',
  //     name: 'Imagen0',
  //     src: 'images/gallery0/74807.jpg'
  //   },
  //   {
  //     id: 'i1',
  //     name: 'Imagen1',
  //     src: 'images/gallery0/209405744_1.jpg'
  //   },
  //   {
  //     id: 'i2',
  //     name: 'Imagen2',
  //     src: 'images/gallery0/adosados-minerva-1024x683.jpg'
  //   },
  //   {
  //     id: 'i3',
  //     name: 'Imagen3',
  //     src: 'images/gallery0/cemar_cocinas.jpg'
  //   },
  //   {
  //     id: 'i4',
  //     name: 'Imagen4',
  //     src: 'images/gallery0/chalet-adosado-desalas.jpg'
  //   },
  //   {
  //     id: 'i5',
  //     name: 'Imagen5',
  //     src: 'images/gallery0/cocina-santos-oferta-line-lah-santiago.jpg'
  //   },
  //   {
  //     id: 'i6',
  //     name: 'Imagen6',
  //     src: 'images/gallery0/cocinas-color-9a-a.jpg'
  //   },
  //   {
  //     id: 'i7',
  //     name: 'Imagen7',
  //     src: 'images/gallery0/Cocinas-modernas-a-medida.jpg'
  //   },
  //   {
  //     id: 'i8',
  //     name: 'Imagen8',
  //     src: 'images/gallery0/corvera-1-1120x450-1.jpg'
  //   },
  //   {
  //     id: 'i9',
  //     name: 'Imagen9',
  //     src: 'images/gallery0/fachada-pareadas.jpg'
  //   },
  //   {
  //     id: 'i10',
  //     name: 'Imagen10',
  //     src: 'images/gallery0/M25043-SM1703-Dilusso-cocinas-malaga.jpg'
  //   }
  // ],
  // galleries: [
  //   { id: 'g0', name: 'Galeria 0', images: [166, 165, 163, 160] },
  //   { id: 'g1', name: 'Galeria 1', images: ['i0', 'i8', 'i7', 'i5', 'i10'] },
  //   { id: 'g2', name: 'Galeria 2', images: ['i1', 'i6', 'i3', 'i2'] },
  //   { id: 'g3', name: 'Galeria 3', images: ['i1'] },
  //   { id: 'g4', name: 'Galeria 4', images: ['i1', 'i0'] },
  //   {
  //     id: 'g5',
  //     name: 'Galeria 5',
  //     images: [
  //       'i0',
  //       'i1',
  //       'i2',
  //       'i3',
  //       'i4',
  //       'i5',
  //       'i6',
  //       'i7',
  //       'i8',
  //       'i9',
  //       'i10'
  //     ]
  //   }
  // ],

  currentGallery: '',
  currentImageIdx: 0
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_CURRENT_GALLERY:
      return { ...state, currentGallery: action.id, currentImageIdx: 0 }
    case SET_CURRENT_IMAGE_IDX:
      const length = action.gallery ? action.gallery.images.length : 0
      return {
        ...state,
        currentImageIdx: Math.min(Math.max(0, action.idx), length - 1)
      }

    default:
      return state
  }
}
export default changeState

export const setCurrentGallery = (id) => ({
  type: SET_CURRENT_GALLERY,
  id
})

export const setCurrentImageIdx = (idx) => (dispatch, getState) => {
  const state = getState()
  const gallery = getCurrentGallery(state)
  dispatch({
    type: SET_CURRENT_IMAGE_IDX,
    idx,
    gallery
  })
}

export const setNextImage = () => (dispatch, getState) => {
  const state = getState()
  const gallery = getCurrentGallery(state)
  dispatch({
    type: SET_CURRENT_IMAGE_IDX,
    idx: currentImageIdxSelector(state) + 1,
    gallery
  })
}

export const setPreviousImage = () => (dispatch, getState) => {
  const state = getState()
  const gallery = getCurrentGallery(state)
  dispatch({
    type: SET_CURRENT_IMAGE_IDX,
    idx: currentImageIdxSelector(state) - 1,
    gallery
  })
}

// export const imagesSelector = (state) => state.gallery.images
// export const galleriesSelector = (state) => state.gallery.galleries

export const imagesSelector = createSelector([dataSelector], (data) => {
  const media = findById(data, DATA_TYPE.MEDIA)

  return media
    ? media.data.reduce((acc, m) => {
        if (m.media_type === 'image') {
          acc.push({
            id: DATA_TYPE.MEDIA + m.id,
            name: m.title.rendered,
            src: m.source_url
          })
        }
        return acc
      }, [])
    : []
})

export const galleriesSelector = createSelector([dataSelector], (data) => {
  const galleries = findById(data, DATA_TYPE.GALLERY)

  return galleries
    ? galleries.data.map((g) => {
        return {
          id: DATA_TYPE.GALLERY + g.id,
          name: g.title.rendered,
          images: Object.keys(g.acf.images_relation).map(
            (key) => DATA_TYPE.MEDIA + g.acf.images_relation[key]
          )
        }
      })
    : []
})
export const currentGallerySelector = (state) => state.gallery.currentGallery
export const currentImageIdxSelector = (state) => state.gallery.currentImageIdx

export const getImageById = () =>
  createSelector([imagesSelector, (_, id) => id], (images, id) => {
    return findById(images, id)
  })

const createGallery = (galleries, images, id) => {
  const gallery = findById(galleries, id)
  if (!gallery) {
    return null
  }
  const galleryImages = gallery.images.reduce((acc, imgId) => {
    const img = findById(images, imgId)
    if (img) {
      acc.push(img)
    }
    return acc
  }, [])
  return {
    id: gallery.id,
    name: gallery.name,
    images: galleryImages
  }
}

export const getGallery = () =>
  createSelector(
    [galleriesSelector, imagesSelector, (_, id) => id],
    (galleries, images, id) => {
      return createGallery(galleries, images, id)
    }
  )

export const getCurrentGallery = createSelector(
  [galleriesSelector, imagesSelector, currentGallerySelector],
  (galleries, images, currentGallery) => {
    return createGallery(galleries, images, currentGallery)
  }
)
