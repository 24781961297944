// import { useCallback, useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import MenuItemExpandable from './MenuItemExpandable'
import { menuOptionsSelector } from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  optionsWrapper: {
    display: 'flex',
    flexFlow: 'column',
    paddingTop: '20px'
  }
}))

const MainMenu = () => {
  const classes = useStyles()
  const options = useSelector(menuOptionsSelector)

  return (
    <div className={classes.optionsWrapper}>
      {options.map((option) => {
        return (
          <MenuItemExpandable
            key={option.id}
            title={option.name}
            optionId={option.id}
          />
        )
      })}
    </div>
  )
}
export default MainMenu
