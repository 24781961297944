const VistaPeatonalIcon = (props) => {
  return (
    <svg viewBox="0 0 23 22" {...props}>
      <path
        fill="fill"
        d="M11.668 12.526h-.477v5.786h.477v-5.786zm-.223-9.156c.922 0 1.685-.763 1.685-1.685S12.367 0 11.445 0 9.76.763 9.76 1.685s.763 1.685 1.685 1.685zm0-2.893a1.207 1.207 0 110 2.416 1.207 1.207 0 110-2.416z"
      />
      <path
        fill="fill"
        d="M9.41 18.916h4.07l.35-7.217h1.462V5.373c0-.763-.636-1.399-1.399-1.399H8.997c-.763 0-1.399.636-1.399 1.399v6.295h1.463l.35 7.248zM8.075 5.373c0-.509.414-.922.922-.922h4.896c.509 0 .922.413.922.922v5.818h-.954v-4.8h-.477v5.054l-.35 6.994H9.857l-.35-6.994V6.39H9.03v4.833h-.954v-5.85z"
      />
      <path
        fill="fill"
        d="M15.07 15.546c-.128 0-.255.096-.255.223s.095.254.223.254c4.832.445 7.375 1.685 7.375 2.67 0 1.336-4.514 2.83-10.968 2.83S.477 20.03.477 18.693c0-.985 2.543-2.225 7.376-2.67.127 0 .222-.127.222-.254s-.127-.223-.254-.223C4.197 15.896 0 16.913 0 18.694 0 20.76 5.818 22 11.445 22c5.627 0 11.445-1.24 11.445-3.306 0-1.78-4.196-2.798-7.82-3.148z"
      />
    </svg>
  )
}
export default VistaPeatonalIcon
