import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring'

import GalleryButton from '../Common/GalleryButton'
import GalleryThumbnail from './GalleryThumbnail'

import {
  getCurrentGallery,
  currentImageIdxSelector,
  setNextImage,
  setPreviousImage
} from '../../modules/reducerGallery'

const THUMBNAIL_HEIGHT = 100
const SCALE_FACTOR = 0.8
const RATIO = 16 / 9
const THUMBNAIL_WIDTH = THUMBNAIL_HEIGHT * RATIO
const THUMBNAIL_SMALL_WIDTH = SCALE_FACTOR * THUMBNAIL_WIDTH
const THUMBNAILS_WIDTH = THUMBNAIL_WIDTH + 2 * SCALE_FACTOR * THUMBNAIL_WIDTH

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: THUMBNAIL_HEIGHT,

    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  thumbnailsWindow: {
    overflow: 'hidden',
    height: '100%',
    width: THUMBNAILS_WIDTH,
    position: 'relative'
  },

  thumbnailsWrapper: {
    position: 'absolute',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'start'
  }
}))

const GalleryThumbnails = () => {
  const currentGallery = useSelector(getCurrentGallery)
  const imgIdx = useSelector(currentImageIdxSelector)
  const dispatch = useDispatch()
  const classes = useStyles()

  const despAnimation = useSpring({
    left: THUMBNAIL_SMALL_WIDTH - imgIdx * THUMBNAIL_SMALL_WIDTH
  })

  const handleNextClick = useCallback(() => {
    dispatch(setNextImage())
  }, [dispatch])

  const handleBackClick = useCallback(() => {
    dispatch(setPreviousImage())
  }, [dispatch])

  return (
    <div className={classes.root}>
      <GalleryButton
        count={currentGallery.images.length}
        currentIdx={imgIdx}
        onClick={handleBackClick}
      />

      <div className={classes.thumbnailsWindow}>
        <animated.div
          className={classes.thumbnailsWrapper}
          style={despAnimation}
        >
          {currentGallery.images.map((image, idx) => (
            <GalleryThumbnail
              key={idx}
              imgIndex={idx}
              imgSrc={image.src}
              width={THUMBNAIL_WIDTH}
              height={THUMBNAIL_HEIGHT}
              scaleFactor={SCALE_FACTOR}
            />
          ))}
        </animated.div>
      </div>
      <GalleryButton
        next={true}
        count={currentGallery.images.length}
        currentIdx={imgIdx}
        onClick={handleNextClick}
      />
    </div>
  )
}
export default GalleryThumbnails
