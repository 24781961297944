import { useMemo } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import Typography from '@material-ui/core/Typography'
import OptionIcon from '../Common/OptionIcon'
import {
  getMenuOption,
  currentMenuOptionSelector,
  menuOpenSelector,
  getMobileMode
} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '40px',
    height: '40px',
    boxSizing: 'border-box',

    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    borderRightWidth: theme.sizes.menu.borderWidth,
    borderRightStyle: 'solid',
    borderRightColor: ({ selected }) =>
      selected ? theme.palette.secondary.main : 'transparent',

    fill: ({ selected }) =>
      selected
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText,
    color: ({ selected }) =>
      selected
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText,
    '&:hover': {
      fill: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      borderRightColor: theme.palette.secondary.main
    },
    backgroundColor: 'transparent'
  },
  title: {
    fontFamily: ({ selected }) =>
      selected ? 'Futura Md Bt' : 'Futura Lt Bt Light',
    fontWeight: ({ selected }) => (selected ? '500' : '')
  },
  iconWrapper: {
    width: '24px',
    height: '40px',
    flexShrink: 0,
    margin: '0 14px 0 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const MenuItemMain = ({ optionId, onClick }) => {
  const currentMenuOption = useSelector(currentMenuOptionSelector)
  const selected = currentMenuOption === optionId
  const gMenuOption = useMemo(getMenuOption, [])
  const option = useSelector((state) => gMenuOption(state, optionId))
  const menuOpen = useSelector(menuOpenSelector)
  const mobileMode = useSelector(getMobileMode)
  const classes = useStyles({ selected })
  const theme = useTheme()

  const despAnimation = useSpring({
    paddingLeft:
      menuOpen || !mobileMode
        ? theme.sizes.menu.paddingLeft
        : theme.sizes.menu.paddingLeftClosed
  })

  return option ? (
    <animated.div
      style={despAnimation}
      className={classes.root}
      onClick={onClick}
    >
      <div className={classes.iconWrapper}>
        <OptionIcon optionId={optionId} />
      </div>
      <Typography variant="h3" className={classes.title}>
        {option.name}
      </Typography>
    </animated.div>
  ) : null
}
export default MenuItemMain
