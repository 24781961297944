import { useCallback, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'
import mergeRefs from 'react-merge-refs'
import { useGesture } from 'react-use-gesture'
import CircularProgress from '@material-ui/core/CircularProgress'
// import { useSprings } from 'react-spring'
import RotatorBar from './RotatorBar'

import { vector, addVectors } from '../../utils/mathFunctions'

import {
  modeSelector,
  setMode,
  zoomAtGlobalPoint,
  MODE,
  rotate,
  translationSelector,
  setTranslation,
  scaleSelector,
  imageSizeSelector,
  ZOOM_FACTOR,
  setRotatorBounds,
  resetView,
  loadingSelector
} from '../../modules/reducerRotator'

const PROGRESS_RADIUS = 50
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  rotator: {
    touchAction: 'none',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    cursor: ({ mode }) => {
      switch (mode) {
        case MODE.PAN:
          return 'grab'
        case MODE.PANNING:
          return 'grabbing'
        case MODE.ROT_MOUSE:
        default:
          return ''
      }
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column'
  },
  progress: {
    position: 'absolute',
    zIndex: 10,
    top: `calc(50% - ${PROGRESS_RADIUS}px)`,
    left: `calc(50% - ${PROGRESS_RADIUS}px)`,
    width: 80,
    height: 80
  }
}))

const Rotator = ({ children, small }) => {
  const domTarget = useRef(null)
  const [rootRef, rootBounds] = useMeasure()
  const [firstImage, setFirstImage] = useState(true)

  const dispatch = useDispatch()
  const mode = useSelector(modeSelector)
  const scale = useSelector(scaleSelector)
  const translation = useSelector(translationSelector)
  const imageSize = useSelector(imageSizeSelector)
  const loading = useSelector(loadingSelector)
  const classes = useStyles({ mode, translation, scale })

  useEffect(() => {
    dispatch(setMode(MODE.BUTTONS))
  }, [dispatch])

  //Cambio tamaño contenedor
  useEffect(() => {
    dispatch(setRotatorBounds(rootBounds))
    if (firstImage) {
      if (
        rootBounds.width > 0 &&
        rootBounds.height > 0 &&
        imageSize.width > 0 &&
        imageSize.height > 0
      ) {
        //Primera imagen en cargarse
        setFirstImage(false)
        dispatch(resetView())
      }
    }
  }, [dispatch, rootBounds, firstImage, imageSize])

  const setModeD = useCallback(
    (value) => {
      dispatch(setMode(value))
    },
    [dispatch]
  )

  const handleWheel = (e) => {
    if (e.deltaY) {
      dispatch(
        zoomAtGlobalPoint(
          e.deltaY > 0 ? 1 + ZOOM_FACTOR : 1 - ZOOM_FACTOR,
          vector(e.pageX, e.pageY)
        )
      )
    }
  }

  useGesture(
    {
      onDragStart: ({ pinching }) => {
        if (pinching) {
          return
        }
        if (mode === MODE.BUTTONS) {
          setModeD(MODE.ROT_MOUSE)
        } else if (mode === MODE.PAN) {
          setModeD(MODE.PANNING)
        }
      },
      onDragEnd: ({ pinching }) => {
        if (pinching) {
          return
        }
        if (mode === MODE.ROT_MOUSE) {
          setModeD(MODE.BUTTONS)
        } else if (mode === MODE.PANNING) {
          setModeD(MODE.PAN)
        }
      },
      onDrag: ({ movement, dragging, first, last, delta, pinching }) => {
        if (pinching) {
          return
        }
        if (mode === MODE.PANNING) {
          let inc = { x: delta[0], y: delta[1] }
          inc.x /= scale
          inc.y /= scale
          dispatch(setTranslation(addVectors(translation, inc)))
        } else if (mode === MODE.ROT_MOUSE) {
          //Giro con el raton.
          linearRotation(delta[0])
        }
      },
      onPinch: ({ delta: [d], origin }) => {
        if (mode === MODE.ROT_MOUSE) {
          setModeD(MODE.BUTTONS)
        } else if (mode === MODE.PANNING) {
          setModeD(MODE.PAN)
        }
        dispatch(
          zoomAtGlobalPoint(
            d > 0 ? 1 + ZOOM_FACTOR / 2 : 1 - ZOOM_FACTOR / 2,
            vector(origin[0], origin[1])
          )
        )
      }
    },
    { domTarget, eventOptions: { passive: false } }
  )

  const linearRotation = useCallback(
    (deltaX) => {
      if (deltaX > 0) dispatch(rotate(1))
      else if (deltaX < 0) dispatch(rotate(-1))
    },
    [dispatch]
  )

  return (
    <div className={classes.root}>
      <div
        ref={mergeRefs([rootRef, domTarget])}
        onWheel={handleWheel}
        className={classes.rotator}
      >
        {children}
      </div>
      {loading ? (
        <CircularProgress
          className={classes.progress}
          size={PROGRESS_RADIUS * 2}
        />
      ) : null}
      <RotatorBar small={small} />
    </div>
  )
}
export default Rotator
