import { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useGesture } from 'react-use-gesture'

import Slider from '@material-ui/core/Slider'
import Title from './Title'

import { setDragEnabled } from '../../modules/reducerMain'
import { getCurrentPromocion } from '../../modules/reducerPromocion'
import {
  priceSelector,
  setPriceRange,
  getSliderPriceRange
} from '../../modules/reducerHousesFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column'
  },
  slider: { margin: '0 20px 8px 20px', maxWidth: '140px' },
  markLabel: {
    top: '-8px',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h6.fontSize
  }
}))

const Price = ({ id }) => {
  const classes = useStyles()
  const price = useSelector(priceSelector)
  const sliderPriceRange = useSelector(getSliderPriceRange)
  const promocion = useSelector(getCurrentPromocion)
  const currency = promocion ? promocion.currency : ''
  const dispatch = useDispatch()

  useEffect(() => {
    //Ajusta priceRange al principio
    if (price[0] < sliderPriceRange[0] || price[1] > sliderPriceRange[1]) {
      dispatch(setPriceRange(sliderPriceRange))
    }
  }, [dispatch, sliderPriceRange, price])

  const handleChange = useCallback(
    (e, newValue) => {
      dispatch(setPriceRange(newValue))
    },
    [dispatch]
  )

  const getMarkValue = (value) => {
    return value + currency
  }

  const bind = useGesture({
    onDragStart: () => {
      dispatch(setDragEnabled(false))
    },
    onDragEnd: () => {
      dispatch(setDragEnabled(true))
    }
  })

  return (
    <div {...bind()} className={classes.root}>
      <Title text={'Precios'} />
      <Slider
        className={classes.slider}
        classes={{ markLabel: classes.markLabel }}
        color="secondary"
        min={sliderPriceRange[0]}
        max={sliderPriceRange[1]}
        step={1000}
        marks={[
          { value: sliderPriceRange[0], label: getMarkValue(price[0]) },
          { value: sliderPriceRange[1], label: getMarkValue(price[1]) }
        ]}
        value={price}
        onChange={handleChange}
      />
    </div>
  )
}
export default Price
