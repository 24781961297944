// import { useCallback } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import DocumentsMenu from './DocumentsMenu'

import { getCurrentPromocion } from '../../modules/reducerPromocion'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const PromotionDocumentsMenu = () => {
  const promocion = useSelector(getCurrentPromocion)
  // const dispatch = useDispatch()
  // const classes = useStyles()
  return promocion ? <DocumentsMenu docIds={promocion.documents} /> : null
}
export default PromotionDocumentsMenu
