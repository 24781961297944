import { useMemo, useCallback } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import LabelCheckBox from '../Common/LabelCheckBox'
import {
  getSubcategory,
  getSubcategoryIsSelected,
  selectSubcategory
} from '../../modules/reducerMap'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const MapSubcategory = ({ id }) => {
  const gSubcategory = useMemo(getSubcategory, [])
  const sub = useSelector((state) => gSubcategory(state, id))
  const gSubcategoryIsSelected = useMemo(getSubcategoryIsSelected, [])
  const selected = useSelector((state) => gSubcategoryIsSelected(state, id))
  const dispatch = useDispatch()
  // const classes = useStyles({ selected })

  const handleChange = useCallback(
    (e) => {
      dispatch(selectSubcategory(id, e.target.checked))
    },
    [dispatch, id]
  )

  return sub ? (
    <LabelCheckBox
      label={sub.name}
      checked={selected}
      onChange={handleChange}
    />
  ) : null
}
export default MapSubcategory
