// import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/core/styles'
import { useDrag } from 'react-use-gesture'

import {
  currentImageIdxSelector,
  setCurrentImageIdx,
  setNextImage,
  setPreviousImage
} from '../../modules/reducerGallery'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 8px',
    boxSizing: 'border-box'
  },
  img: {
    touchAction: 'none',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    border: `1px solid transparent`,
    backgroundColor: theme.palette.primary.main
  }
}))

const GalleryThumbnail = ({ imgIndex, imgSrc, width, height, scaleFactor }) => {
  const currentImgIdx = useSelector(currentImageIdxSelector)
  const loadImage = Math.abs(imgIndex - currentImgIdx) <= 2
  const dispatch = useDispatch()
  const classes = useStyles()

  const sizeAnimation = useSpring({
    width: imgIndex === currentImgIdx ? width : width * scaleFactor,
    height: imgIndex === currentImgIdx ? height : height * scaleFactor,
    config: { duration: 200 }
  })

  const bind = useDrag(
    ({ movement, cancel, distance, active, dragging, last }) => {
      if (last && movement[0] === 0) {
        //Click en imagen
        dispatch(setCurrentImageIdx(imgIndex))
      }
      if (active && dragging) {
        if (movement[0] < -10) {
          cancel(true)
          dispatch(setNextImage())
        } else if (movement[0] > 10) {
          cancel(true)
          dispatch(setPreviousImage())
        }
      }
    }
  )

  return (
    <animated.div style={sizeAnimation} className={classes.root}>
      {loadImage && (
        <animated.img
          {...bind()}
          className={classes.img}
          src={imgSrc}
          alt={''}
          draggable="false"
        />
      )}
    </animated.div>
  )
}
export default GalleryThumbnail
