const VolverIcon = (props) => {
  return (
    <svg viewBox="0 0 25 15" {...props}>
      <path
        fill="fill"
        d="M18.82 3.709H1.48l1.874-1.96a.455.455 0 000-.624.406.406 0 00-.595 0l-2.15 2.25a1.14 1.14 0 00-.299.771v.023c.011.265.11.53.298.738L2.78 7.18c.077.08.187.127.297.127.11 0 .22-.046.298-.127a.454.454 0 000-.623l-1.874-1.96H18.82c2.513 0 4.553 2.133 4.553 4.762 0 2.63-2.04 4.763-4.553 4.763h-5.975a.43.43 0 00-.42.438.43.43 0 00.42.439h5.975c2.976 0 5.39-2.538 5.39-5.64 0-3.113-2.425-5.65-5.39-5.65z"
      />
    </svg>
  )
}
export default VolverIcon
