import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.contrastText,
    margin: '12px 0px'
  }
}))

const Title = ({ text }) => {
  const classes = useStyles()
  return (
    <Typography variant="h3" className={classes.title}>
      {text}
    </Typography>
  )
}
export default Title
