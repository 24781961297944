import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Logo from '../Logos/Logo'
import HomeTitle from '../Logos/HomeTitle'

import { homeClicked } from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: '100%'
  },
  toolbar: {
    height: theme.sizes.toolbar.height,
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'
  },
  logo: { margin: '0 24px 0 0' },
  homeTitle: { margin: '17px 0 0 0' }
}))

const Header = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(homeClicked())
  }, [dispatch])

  return (
    <AppBar position="fixed" className={classes.appBar} onClick={handleClick}>
      <Toolbar className={classes.toolbar}>
        <Logo width={34} height={'auto'} className={classes.logo} />
        <HomeTitle width={230} height={'auto'} className={classes.homeTitle} />
      </Toolbar>
    </AppBar>
  )
}
export default Header
