import { createSelector } from 'reselect'
import { dataSelector, DATA_TYPE, MEDIA_TYPE } from './reducerRead'
import {
  currentPromocionSelector,
  getCurrentPromocion
} from './reducerPromocion'
import { findById } from '../utils/utils'

export const SELECT_FAVORITE_HOUSE = 'SELECT_FAVORITE_HOUSE'
export const SET_CURRENT_HOUSE = 'SET_CURRENT_HOUSE'

const initialState = {
  // houseStates: [
  //   { id: 'hs0', name: 'Disponible', color: '#09ce1d' },
  //   { id: 'hs1', name: 'Bajo opción de compra', color: '#e9b707' },
  //   { id: 'hs2', name: 'Reservado', color: '#d61408' },
  //   { id: 'hs3', name: 'Vendido', color: '#5c667b' },
  //   { id: 'hs4', name: 'No disponible', color: '#5c667b' }
  // ],
  // houseTypologies: [
  //   { id: 't0', name: 'Vivienda 1 dormitorio' },
  //   { id: 't1', name: 'Vivienda 2 dormitorios' },
  //   { id: 't2', name: 'Unifamiliar adosada' },
  //   { id: 't3', name: 'Vivienda ático' }
  // ],
  // houseFloors: [
  //   { id: 'f0', name: 'Planta baja' },
  //   { id: 'f1', name: '1ª Planta' },
  //   { id: 'f2', name: '2ª Planta' },
  //   { id: 'f3', name: '3ª Planta' }
  // ],
  // houseOrientation: [
  //   { id: 'o0', name: 'Norte' },
  //   { id: 'o1', name: 'Norte/Este' },
  //   { id: 'o2', name: 'Este' },
  //   { id: 'o3', name: 'Sur/Este' },
  //   { id: 'o4', name: 'Sur' },
  //   { id: 'o5', name: 'Sur/Oeste' },
  //   { id: 'o6', name: 'Oeste' },
  //   { id: 'o7', name: 'Norte/Oeste' }
  // ],
  // houses: [
  //   {
  //     id: 'h0',
  //     img: 'imagen',
  //     name: 'House0',
  //     typology: 't0',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o1',
  //     price: 60000,
  //     state: 'hs3',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h1',
  //     img: 'imagen',
  //     name: 'House1',
  //     typology: 't1',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o2',
  //     price: 70000,
  //     state: 'hs3',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h2',
  //     img: 'imagen',
  //     name: 'House2',
  //     typology: 't2',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o1',
  //     price: 55000,
  //     state: 'hs0',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h3',
  //     img: 'imagen',
  //     name: 'House3',
  //     typology: 't3',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o6',
  //     price: 100000,
  //     state: 'hs1',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h4',
  //     img: 'imagen',
  //     name: 'House4',
  //     typology: 't0',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o4',
  //     price: 99000,
  //     state: 'hs2',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h5',
  //     img: 'imagen',
  //     name: 'House5',
  //     typology: 't1',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o0',
  //     price: 106000,
  //     state: 'hs4',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h6',
  //     img: 'imagen',
  //     name: 'House6',
  //     typology: 't2',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o0',
  //     price: 100700,
  //     state: 'hs1',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h7',
  //     img: 'imagen',
  //     name: 'House7',
  //     typology: 't3',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o7',
  //     price: 14440,
  //     state: 'hs1',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h8',
  //     img: 'imagen',
  //     name: 'House8',
  //     typology: 't3',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o6',
  //     price: 100067,
  //     state: 'hs3',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h9',
  //     img: 'imagen',
  //     name: 'House9',
  //     typology: 't2',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o5',
  //     price: 140000,
  //     state: 'hs3',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h10',
  //     img: 'imagen',
  //     name: 'House10',
  //     typology: 't2',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o2',
  //     price: 140001,
  //     state: 'hs0',
  //     floor: 'f1'
  //   },
  //   {
  //     id: 'h11',
  //     img: 'imagen',
  //     name: 'House11',
  //     typology: 't1',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o2',
  //     price: 87000,
  //     state: 'hs0',
  //     floor: 'f0'
  //   }
  // ],
  favorites: new Set(),
  currentHouse: ''
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SELECT_FAVORITE_HOUSE:
      const favorites = new Set(state.favorites)
      action.select ? favorites.add(action.id) : favorites.delete(action.id)
      return {
        ...state,
        favorites
      }
    case SET_CURRENT_HOUSE:
      return { ...state, currentHouse: action.id }
    default:
      return state
  }
}
export default changeState

export const selectFavoriteHouse = (id, select) => ({
  type: SELECT_FAVORITE_HOUSE,
  id,
  select
})

export const setCurrentHouse = (id) => ({
  type: SET_CURRENT_HOUSE,
  id
})

export const toggleFavorite = (id) => (dispatch, getState) => {
  const state = getState()
  const select = !favoritesSelector(state).has(id)
  dispatch({
    type: SELECT_FAVORITE_HOUSE,
    id,
    select
  })
}

// export const houseStatesSelector = (state) => state.houses.houseStates
// export const houseTypologiesSelector = (state) => state.houses.houseTypologies
// export const houseFloorsSelector = (state) => state.houses.houseFloors
// export const houseOrientationSelector = (state) => state.houses.houseOrientation
// export const housesSelector = (state) => state.houses.houses

export const housesSelector = createSelector(
  [dataSelector, currentPromocionSelector],
  (data, promocion) => {
    const allHouses = findById(data, DATA_TYPE.HOUSE)
    if (!allHouses) {
      return []
    }
    const houses = allHouses.data.filter(
      (house) =>
        DATA_TYPE.PROMOCION + house.acf.promocion_vivienda === promocion
    )
    const media = findById(data, DATA_TYPE.MEDIA)

    return houses.map((h) => {
      let documents = h.acf.pdfs_relation ? h.acf.pdfs_relation : []
      documents = documents.map((docId) => MEDIA_TYPE.PDF + docId)
      const img = media.data.find((m) => m.id === h.featured_media)
      return {
        id: DATA_TYPE.HOUSE + h.id,
        name: h.title.rendered,
        description: h.acf.descripcion ? h.acf.descripcion : '',
        img: img ? img.source_url : '',
        typology: h.tipo_vivienda[0]
          ? DATA_TYPE.HOUSE_TYPE + h.tipo_vivienda[0]
          : '',
        state: h.estado_vivienda[0]
          ? DATA_TYPE.HOUSE_STATE + h.estado_vivienda[0]
          : '',
        floor: h.planta[0] ? DATA_TYPE.HOUSE_FLOOR + h.planta[0] : '',
        price: h.acf.precio ? parseInt(h.acf.precio) : 0,
        gallery: h.acf.galeria ? DATA_TYPE.GALLERY + h.acf.galeria : '',
        documents,
        orientation: h.orientacion[0]
          ? DATA_TYPE.ORIENTATION + h.orientacion[0]
          : ''
      }
    })
  }
)

const getChildrenElements = (promocion, taxonomy) => {
  if (!promocion || !taxonomy) {
    return []
  }
  const root = taxonomy.data.find((typo) => typo.name === promocion.name)
  return taxonomy.data.filter((typology) => typology.parent === root.id)
}

export const houseTypologiesSelector = createSelector(
  [dataSelector, getCurrentPromocion],
  (data, promocion) => {
    const typologies = getChildrenElements(
      promocion,
      findById(data, DATA_TYPE.HOUSE_TYPE)
    )

    return typologies.map((typo) => {
      return {
        id: DATA_TYPE.HOUSE_TYPE + typo.id,
        name: typo.name,
        area: parseInt(typo.acf.superficie),
        aditionalArea: parseInt(typo.acf.espacio_adicional),
        rooms: parseInt(typo.acf.habitaciones),
        baths: parseInt(typo.acf.banos),
        nFloors: parseInt(typo.acf.numero_de_plantas),
        garage: typo.acf.garaje,
        areaGarage: typo.acf.garaje ? parseInt(typo.acf.superficie_garaje) : 0,
        terrace: typo.acf.terraza,
        areaTerrace: typo.acf.terraza
          ? parseInt(typo.acf.superficie_terraza)
          : 0
      }
    })
  }
)

export const houseStatesSelector = createSelector(
  [dataSelector, getCurrentPromocion],
  (data, promocion) => {
    const states = getChildrenElements(
      promocion,
      findById(data, DATA_TYPE.HOUSE_STATE)
    )

    return states.map((hs) => {
      return {
        id: DATA_TYPE.HOUSE_STATE + hs.id,
        name: hs.name,
        color: hs.acf.color
      }
    })
  }
)

export const houseFloorsSelector = createSelector(
  [dataSelector, getCurrentPromocion],
  (data, promocion) => {
    const houseFloors = getChildrenElements(
      promocion,
      findById(data, DATA_TYPE.HOUSE_FLOOR)
    )
    return houseFloors.map((hf) => {
      return {
        id: DATA_TYPE.HOUSE_FLOOR + hf.id,
        name: hf.name
      }
    })
  }
)

export const houseOrientationSelector = createSelector(
  [dataSelector, getCurrentPromocion],
  (data, promocion) => {
    const orientations = getChildrenElements(
      promocion,
      findById(data, DATA_TYPE.ORIENTATION)
    )

    return orientations.map((o) => {
      return {
        id: DATA_TYPE.ORIENTATION + o.id,
        name: o.name
      }
    })
  }
)

export const favoritesSelector = (state) => state.houses.favorites
export const currentHouseSelector = (state) => state.houses.currentHouse

export const getHouse = () =>
  createSelector([housesSelector, (_, id) => id], (houses, id) => {
    return findById(houses, id)
  })

export const getCurrentHouse = createSelector(
  [housesSelector, currentHouseSelector],
  (houses, current) => {
    return findById(houses, current)
  }
)

export const getOrientation = () =>
  createSelector(
    [houseOrientationSelector, (_, id) => id],
    (orientations, id) => {
      return findById(orientations, id)
    }
  )

export const getState = () =>
  createSelector([houseStatesSelector, (_, id) => id], (houseStates, id) => {
    return findById(houseStates, id)
  })

export const getTypology = () =>
  createSelector([houseTypologiesSelector, (_, id) => id], (typologies, id) => {
    return findById(typologies, id)
  })

export const getHouseDetails = () =>
  createSelector(
    [
      housesSelector,
      houseOrientationSelector,
      houseTypologiesSelector,
      favoritesSelector,
      getCurrentPromocion,
      (_, id) => id
    ],
    (houses, houseOrientation, typologies, favorites, promocion, id) => {
      const house = findById(houses, id)
      if (!house) {
        return { house: null, favorite: false, details: [] }
      }
      const o = findById(houseOrientation, house.orientation)
      const typo = findById(typologies, house.typology)
      const currency = promocion ? promocion.currency : ''
      const areaUnit = promocion ? promocion.areaUnit : ''
      return {
        house,
        favorite: favorites.has(house.id),
        details: house
          ? [
              ['Precio:', house.price + currency],
              ['Superficie:', typo ? typo.area + ' ' + areaUnit : '?'],
              [
                'Espacio adicional: ',
                typo ? typo.aditionalArea + ' ' + areaUnit : '?'
              ],
              ['Orientación: ', o ? o.name : '?'],
              ['Habitaciones: ', typo ? typo.rooms : '?'],
              ['Baños/Aseos: ', typo ? typo.baths : '?']
            ]
          : []
      }
    }
  )

export const getPriceRange = createSelector([housesSelector], (houses) => {
  return houses.reduce(
    (acc, house) => {
      if (house.price > acc[1]) {
        acc[1] = house.price
      }
      if (house.price < acc[0]) {
        acc[0] = house.price
      }
      return acc
    },
    houses.length > 0 ? [houses[0].price, houses[0].price] : [0, 0]
  )
})
