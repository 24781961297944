// import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getCurrentGallery } from '../../modules/reducerGallery'

import GalleryCenter from './GalleryCenter'
import GalleryBar from './GalleryBar'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: theme.palette.gallery.background,
    position: 'relative'
  }
}))

const Gallery = () => {
  const classes = useStyles()
  const currentGallery = useSelector(getCurrentGallery)

  return currentGallery ? (
    <div className={classes.root}>
      <GalleryCenter />
      <GalleryBar />
    </div>
  ) : null
}
export default Gallery
