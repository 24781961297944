import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTransition, animated } from 'react-spring'
import SubmenuBar from './SubmenuBar'
import MenuItemMain from './MenuItemMain'
import MapMenu from '../Map/MapMenu'
import FilterMenu from '../Filter/FilterMenu'
import HouseDetails from '../HouseDetails/HouseDetails'
import PromotionDocumentsMenu from '../Documents/PromotionDocumentsMenu'
import HouseDocumentsMenu from '../Documents/HouseDocumentsMenu'
import HouseGalleriesMenu from '../Galleries/HouseGalleriesMenu'
import PromotionGalleriesMenu from '../Galleries/PromotionGalleriesMenu'

// import VideosMenu from '../Videos/VideosMenu'

import {
  menuOpenSelector,
  currentMenuOptionSelector,
  currentSubmenuSelector,
  SUBMENUS,
  setMenuOpen
} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column'
  },
  contentWrapper: {
    boxSizing: 'border-box',
    width: theme.sizes.menu.width,
    marginTop: 12,
    marginBottom: 8,
    paddingLeft: theme.sizes.menu.paddingLeft,
    paddingRight: theme.sizes.menu.borderWidth,
    overflow: 'auto'
  }
}))

const Submenu = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const menuOpen = useSelector(menuOpenSelector)
  const currentSubmenu = useSelector(currentSubmenuSelector)
  const currentMenuOption = useSelector(currentMenuOptionSelector)

  const getSubMenu = () => {
    if (currentSubmenu === SUBMENUS.PLANO_SITUACION) {
      return <MapMenu />
    } else if (currentSubmenu === SUBMENUS.FILTRO) {
      return <FilterMenu />
    } else if (currentSubmenu === SUBMENUS.DETALLES_CASA) {
      return <HouseDetails />
    } else if (currentSubmenu === SUBMENUS.DOCUMENTOS_PROMOCION) {
      return <PromotionDocumentsMenu />
    } else if (currentSubmenu === SUBMENUS.DOCUMENTOS_VIVIENDA) {
      return <HouseDocumentsMenu />
    } else if (currentSubmenu === SUBMENUS.GALERIAS_VIVIENDA) {
      return <HouseGalleriesMenu />
    } else if (currentSubmenu === SUBMENUS.GALERIAS_PROMOCION) {
      return <PromotionGalleriesMenu />
    }
    return null
  }

  const contentTransition = useTransition(menuOpen, null, {
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    }
  })

  const handleMenuItemClick = useCallback(() => {
    dispatch(setMenuOpen(true))
  }, [dispatch])

  return (
    <div className={classes.root}>
      <SubmenuBar />
      <MenuItemMain
        optionId={currentMenuOption}
        onClick={handleMenuItemClick}
      />
      {contentTransition.map(
        ({ item, props, key }, idx) =>
          item && (
            <animated.div
              key={idx}
              style={props}
              className={classes.contentWrapper}
            >
              {getSubMenu()}
            </animated.div>
          )
      )}
    </div>
  )
}
export default Submenu
