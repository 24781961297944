// import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { useSelector, useDispatch } from 'react-redux'
import Paper from '@material-ui/core/Paper'

import ImageHouseStateGroup from '../Common/ImageHouseStateGroup'
import HouseDetailsList from '../Common/HouseDetailsList'
import ShowDetailsButton from './ShowDetailsButton'
import FavoriteButton from '../Common/FavoriteButton'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10,
    width: '300px',
    // height: '100%',
    // maxHeight: '300px',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'relative',

    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    padding: 12,
    width: '100%',
    height: '60px',
    borderRadius: '4px 4px 0 0'
  },
  detailsWrapper: {
    boxSizing: 'border-box',
    width: '100%',
    padding: 12
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0 12px 0'
  },
  favButton: { position: 'absolute', top: 0, right: 0 }
}))

const TooltipContent = ({ houseId }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <div className={classes.bar}>
        <ImageHouseStateGroup houseId={houseId} />
      </div>
      <div className={classes.detailsWrapper}>
        <HouseDetailsList houseId={houseId} />
      </div>
      <div className={classes.buttonWrapper}>
        <ShowDetailsButton houseId={houseId} />
      </div>

      <FavoriteButton className={classes.favButton} houseId={houseId} />
    </Paper>
  )
}
export default TooltipContent
