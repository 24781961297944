import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import {} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '24px',
    width: ({ width }) => width + 'px',
    margin: 0
  },
  button: { textTransform: 'none', width: '50%', padding: 0 },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main
    }
  }
}))

const TwoButtons = ({ leftText, rightText, leftSelected, width, onClick }) => {
  const classes = useStyles({ width })

  return (
    <ButtonGroup color="secondary" className={classes.root}>
      <Button
        className={`${classes.button} ${leftSelected ? classes.selected : ''}`}
        onClick={onClick ? () => onClick('left') : null}
      >
        <Typography variant="h4">{leftText}</Typography>
      </Button>
      <Button
        className={`${classes.button} ${!leftSelected ? classes.selected : ''}`}
        onClick={onClick ? () => onClick('right') : null}
      >
        <Typography variant="h4">{rightText}</Typography>
      </Button>
    </ButtonGroup>
  )
}
export default TwoButtons
