import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { getSegmentDescription } from '../../modules/reducerGeneralView'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '200px',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  title: {
    fontFamily: 'Futura Md Bt',
    boxSizing: 'border-box',
    width: '100%',
    height: '24px',
    paddingLeft: '12px',
    textTransform: 'uppercase',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'
  },
  address: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '8px 12px',
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    color: theme.palette.other.detail
  }
}))

const GeneralViewTooltipContent = ({ segmentId }) => {
  const classes = useStyles()
  const gSegmentDescription = useMemo(getSegmentDescription, [])
  const segmentDescription = useSelector((state) =>
    gSegmentDescription(state, segmentId)
  )

  return segmentDescription ? (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {segmentDescription.name}
      </Typography>
      <Typography variant="h5" className={classes.address}>
        {segmentDescription.address}
      </Typography>
    </div>
  ) : null
}
export default GeneralViewTooltipContent
