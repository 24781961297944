import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    '&:hover': { color: theme.palette.secondary.main },
    color: 'inherit',
    padding: '4px'
  },

  labelRoot: {
    margin: 0,
    fontSize: theme.typography.h6.fontSize,
    color: ({ checked }) =>
      checked
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText,
    '&:hover': { color: theme.palette.secondary.main }
  },
  label: {
    fontSize: theme.typography.h4.fontSize
  }
}))

const LabelCheckBox = ({ label, checked, onChange }) => {
  const classes = useStyles({ checked })

  return (
    <FormControlLabel
      classes={{ root: classes.labelRoot, label: classes.label }}
      control={
        <Checkbox
          className={classes.checkbox}
          size="small"
          checked={checked}
          onChange={onChange ? onChange : null}
          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 15 }} />}
          checkedIcon={<CheckBoxIcon style={{ fontSize: 15 }} />}
        />
      }
      label={label}
    />
  )
}
export default LabelCheckBox
