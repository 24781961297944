const HeartIcon = ({ filled, ...props }) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      {filled ? (
        <path
          d="M11.578 0C10.156 0 8.822.665 8 1.758 7.178.665 5.844 0 4.422 0 1.978 0 0 1.908 0 4.224c0 5.317 7.6 9.54 7.911 9.712l.089.043.089-.043C8.4 13.786 16 9.562 16 4.224 16 1.908 14.022 0 11.578 0z"
          fill="fill"
        />
      ) : (
        <path
          d="M11.516 0A4.515 4.515 0 008.01 1.632 4.56 4.56 0 004.484 0C2.003 0 0 1.925 0 4.29c0 5.294 7.51 9.459 7.837 9.626l.152.084.153-.084c.326-.167 7.836-4.332 7.836-9.626C16 1.925 13.976 0 11.516 0zM7.989 13.268C6.901 12.64.653 8.789.653 4.269.653 2.26 2.373.628 4.484.628c1.328 0 2.526.628 3.244 1.695l.283.418.283-.418C8.99 1.256 10.188.628 11.516.628c2.111 0 3.83 1.632 3.83 3.662 0 4.52-6.247 8.35-7.357 8.978z"
          fill="fill"
        />
      )}
    </svg>
  )
}
export default HeartIcon

/*

*/
