import { createSelector } from 'reselect'
// import { } from './reducerRotatorGroups'
import { findById } from '../utils/utils'

export const SET_IMAGES = 'SET_IMAGES'
export const GENERAL_VIEW_MOUSE_OVER_SEGMENT = 'GENERAL_VIEW_MOUSE_OVER_SEGMENT'
export const GENERAL_VIEW_SELECT_SEGMENT = 'GENERAL_VIEW_SELECT_SEGMENT'

const initialState = {
  images: [],
  hoverSegment: '',
  selectedSegments: new Set(),
  segmentsDescription: [
    {
      id: '#60ff00ff',
      name: 'LH 1',
      address: 'Calle Lomas del Higuerón nº 2 \r\n29300, Benalmádena',
      labelPosition: 'top'
    },
    {
      id: '#e7c19eff',
      name: 'LH 2',
      address: 'Calle Lomas del Higuerón nº 2 \r\n29300, Benalmádena',
      labelPosition: 'top'
    },
    {
      id: '#a9cbc7ff',
      name: 'LH 3',
      address: 'Calle Lomas del Higuerón nº 2 \r\n29300, Benalmádena',
      labelPosition: 'top'
    },
    {
      id: '#e5bcc9ff',
      name: 'LH 4',
      address: 'Calle Lomas del Higuerón nº 2 \r\n29300, Benalmádena',
      labelPosition: 'top'
    },
    {
      id: '#677de0ff',
      name: 'LH 5',
      address: 'Calle Lomas del Higuerón nº 2 \r\n29300, Benalmádena',
      labelPosition: 'top'
    },
    {
      id: '#1e401eff',
      name: 'LH 6',
      address: 'Calle Lomas del Higuerón nº 2 \r\n29300, Benalmádena',
      labelPosition: 'top'
    },
    {
      id: '#8e2c9aff',
      name: 'LH 7',
      address: 'Calle Lomas del Higuerón nº 2 \r\n29300, Benalmádena',
      labelPosition: 'top'
    },
    {
      id: '#ff0000ff',
      name: 'LH 8',
      address: 'Calle Lomas del Higuerón nº 2 \r\n29300, Benalmádena',
      labelPosition: 'top'
    },
    {
      id: '#fff600ff',
      name: 'Parque',
      address: 'Calle Lomas del Higuerón nº 2 \r\n29300, Benalmádena',
      labelPosition: 'top'
    }
  ]
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_IMAGES:
      return { ...state, images: action.images }
    case GENERAL_VIEW_MOUSE_OVER_SEGMENT:
      return {
        ...state,
        hoverSegment: action.segment
      }
    case GENERAL_VIEW_SELECT_SEGMENT: {
      const selectedSegments = new Set(state.selectedSegments)
      action.select
        ? selectedSegments.add(action.id)
        : selectedSegments.delete(action.id)
      return {
        ...state,
        selectedSegments
      }
    }
    default:
      return state
  }
}
export default changeState

export const setImages = (images) => ({
  type: SET_IMAGES,
  images
})

export const mouseOverSegment = (segment) => ({
  type: GENERAL_VIEW_MOUSE_OVER_SEGMENT,
  segment
})

export const setSegmentSelected = (id, select) => ({
  type: GENERAL_VIEW_SELECT_SEGMENT,
  id,
  select
})

export const toggleSegmentSelection = (id) => (dispatch, getState) => {
  const state = getState()
  const select = selectedSegmentSelector(state).has(id) < 0
  dispatch({
    type: GENERAL_VIEW_SELECT_SEGMENT,
    id,
    select
  })
}

export const imagesSelector = (state) => state.generalView.images
export const hoverSegmentSelector = (state) => state.generalView.hoverSegment
export const selectedSegmentSelector = (state) =>
  state.generalView.selectedSegments
export const segmentsDescriptionSelector = (state) =>
  state.generalView.segmentsDescription

export const getSegmentDescription = () =>
  createSelector(
    [segmentsDescriptionSelector, (_, id) => id],
    (segments, id) => {
      return findById(segments, id)
    }
  )
