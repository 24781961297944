import { useCallback, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import ClearIcon from '@material-ui/icons/Clear'
import DescargaIcon from '../Icons/DescargaIcon'
import {
  downloadSelector,
  getDocument,
  removeDownload
} from '../../modules/reducerDocuments'
// import { setUrl, urlSelector } from '../../modules/reducerPDFViewer'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    paddingTop: 0,
    paddingLeft: theme.sizes.menu.paddingLeft
  },
  // wrapper: { '&:hover': { color: theme.palette.secondary.main } },
  listItem: {
    display: 'flex',
    flexFlow: 'row',
    cursor: 'pointer',
    margin: '8px 0px',
    height: '20px', //Hace un efecto raro cuando se expande si so se pone
    color: ({ showIcons }) => (showIcons ? theme.palette.secondary.main : '')
    // '&:hover': { color: theme.palette.secondary.main }
  },
  itemText: {
    overflow: 'hidden',
    marginRight: ({ showIcons }) => (showIcons ? '40px' : 0),
    '&:hover': { marginRight: '40px' }
  },
  listItemIcon: { minWidth: 0, paddingRight: 4, color: 'inherit' },
  iconButton: {
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main
  }
}))

const DownloadItem = ({ id }) => {
  const [hover, setHover] = useState(false)
  const [selected, setSelected] = useState(false)
  const gDocument = useMemo(getDocument, [])
  const doc = useSelector((state) => gDocument(state, id))

  const showIcons = hover || selected
  const classes = useStyles({ showIcons })
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (doc) => {
      setSelected(!selected)
      // dispatch(setUrl(url))
    },
    [selected]
  )

  const handleDownloadClick = useCallback(
    (e) => {
      const a = document.createElement('a')
      a.href = doc.src
      a.download = `${doc.name}.pdf`
      a.click()
      a.remove()
      dispatch(removeDownload(id))
    },
    [dispatch, doc, id]
  )
  /*
  const handleDownloadClick = useCallback(
    (e) => {
      fetch(doc.src).then((response) => {
        return response.blob().then((b) => {
          var a = document.createElement('a')
          a.href = URL.createObjectURL(b)
          a.style.display = 'none'
          a.setAttribute('download', `${doc.name}.pdf`)
          a.click()
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(doc.src)
            a.remove()
            dispatch(removeDownload(id))
          }, 100)
        })
      })
    },
    [dispatch, doc, id]
  )
  */

  const handleRemoveClick = useCallback(
    (doc) => {
      dispatch(removeDownload(id))
    },
    [dispatch, id]
  )

  const handleMouseOver = useCallback(() => {
    setHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  return doc ? (
    <div
      className={classes.wrapper}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <ListItem
        className={classes.listItem}
        role={undefined}
        dense
        disableGutters
        onClick={handleClick}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <FiberManualRecordIcon disableRipple style={{ fontSize: 12 }} />
        </ListItemIcon>
        <ListItemText
          className={classes.itemText}
          primary={doc.name}
          primaryTypographyProps={{ variant: 'h3' }}
        />
        {showIcons ? (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              className={classes.iconButton}
              onClick={handleDownloadClick}
            >
              <DescargaIcon width={20} height={24} fill={'inherit'} />
            </IconButton>
            <IconButton
              edge="end"
              className={classes.iconButton}
              onClick={handleRemoveClick}
            >
              <ClearIcon />
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    </div>
  ) : null
}

const DownloadsMenu = () => {
  const classes = useStyles()
  const downloads = useSelector(downloadSelector)

  return downloads.length > 0 ? (
    <List className={classes.root}>
      {downloads.map((docId, idx) => (
        <DownloadItem key={idx} id={docId} />
      ))}
    </List>
  ) : null
}

export default DownloadsMenu
