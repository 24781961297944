// import { useCallback, useState, useRef, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import { useSelector, useDispatch } from 'react-redux'

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '34.2px',
//     height: '49.7px',
//     margin: '0 24.5px 2.1px 29px'
//   }
// }))

const Logo = ({ width, height, ...props }) => {
  // const classes = useStyles()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 35 50"
      {...props}
    >
      <path
        fill="#fff"
        d="M31.185 15.177v19.754h-19.09v3.401h22.43V18.106l-3.34-2.929zM3.641 19.662L17.406 7.657l5.536 4.836V8.008l-5.536-4.82L.302 18.106v20.226h3.34v-18.67z"
      />
      <path
        fill="#CFA57E"
        d="M9.553 0H6.2v10.006l3.354-2.974V0zm19.06 41.047h-3.34v8.649h3.34v-8.649zm-3.34-13.24v4.576h3.34V6.788h-3.34V24.42H9.553v-6.758L6.2 20.578v22.331h3.354v-15.1h15.72z"
      />
    </svg>
  )
}
export default Logo
