import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { setCurrentHouse } from '../../modules/reducerHouses'
import { setCurrentSubmenu, SUBMENUS } from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 88,
    textTransform: 'none',
    borderRadius: '50px',
    padding: 2,
    color: theme.palette.other.greyTable,
    borderColor: theme.palette.other.greyTable,
    '&:hover': {
      backgroundColor: theme.palette.other.greyTable,
      color: theme.palette.primary.contrastText
    }
  }
}))

const ShowDetailsButton = ({ houseId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (e) => {
      dispatch(setCurrentHouse(houseId))
      dispatch(setCurrentSubmenu(SUBMENUS.DETALLES_CASA))
    },
    [dispatch, houseId]
  )

  return (
    <Button className={classes.root} variant="outlined" onClick={handleClick}>
      <Typography variant="h4">Ver detalle</Typography>
    </Button>
  )
}
export default ShowDetailsButton
