const PdfIcon = (props) => {
  return (
    <svg viewBox="0 0 22 23" {...props}>
      <path
        fill="fill"
        d="M16.829 0H3.348v3.52H0v6.106h3.348V23h17.67V4.118L16.828 0zm3.348 3.995h-3.223V.827l3.223 3.168zm-19.694 0h14.323V9.15H.483V3.995zm3.33 18.53v-12.9h11.458V3.52H3.813V.475h12.658v3.748c0 .124.107.23.232.23h3.814v18.072H3.813z"
      />
      <path
        fill="fill"
        d="M14.25 17.122c-.662-.422-1.235-.897-1.485-1.231-.144-.176-.287-.476-.412-.828.34-1.284.59-2.886.215-3.449a.522.522 0 00-.466-.264c-.268 0-.483.141-.626.388-.34.686-.054 2.235.376 3.36-.144.529-.305 1.021-.448 1.32a15.59 15.59 0 01-.967 1.672c-1.414.563-3.025 1.39-3.24 2.147-.072.247 0 .475.197.669.143.14.322.211.519.211.054 0 .107 0 .161-.018.824-.14 1.88-1.46 2.686-2.657.608-.246 1.2-.44 1.629-.545.465-.106 1.092-.23 1.754-.3 1.128.704 2.453 1.303 3.151 1.144a.658.658 0 00.537-.563.741.741 0 00-.25-.721c-.591-.51-2.077-.475-3.33-.335zm-6.265 3.52c-.108.018-.197 0-.251-.07-.09-.088-.09-.159-.072-.212.126-.44 1.146-1.038 2.274-1.548-.698.915-1.45 1.742-1.951 1.83zm3.902-8.693c.054-.123.126-.141.197-.141.018 0 .036 0 .072.053.215.316.107 1.302-.107 2.323-.233-.88-.358-1.83-.162-2.235zm.376 5.508c-.25.07-.644.176-1.11.352.323-.51.555-.95.663-1.197.09-.193.197-.475.304-.827.09.158.161.3.233.405.215.281.645.668 1.181 1.038-.716.106-1.253.229-1.27.229zm5.067.668c-.018.106-.072.159-.18.177-.447.105-1.36-.264-2.237-.757 1.038-.07 1.987-.018 2.327.281.09.07.126.159.09.3zM5.066 4.91H3.831v3.062h.627V6.863h.608c.359 0 .645-.088.842-.264a.921.921 0 00.304-.704.922.922 0 00-.304-.704c-.197-.193-.483-.281-.842-.281zm.394 1.337c-.09.088-.215.14-.394.14h-.608V5.386h.608c.18 0 .305.053.394.14.09.089.126.212.126.37a.473.473 0 01-.126.352zM7.752 4.91H6.678v3.062h1.074c.376 0 .698-.123.949-.352.25-.247.376-.546.376-.915v-.51c0-.37-.126-.687-.376-.916a1.31 1.31 0 00-.95-.37zm.68 1.795c0 .246-.071.44-.197.58a.68.68 0 01-.519.23h-.412V5.401h.412c.233 0 .394.07.52.23.125.14.196.334.196.58v.493zM9.56 7.972h.627V6.705h1.307V6.23h-1.307v-.845h1.521V4.91H9.56v3.062z"
      />
    </svg>
  )
}
export default PdfIcon
