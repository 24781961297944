// import { useCallback } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import Backdrop from '@material-ui/core/Backdrop'
import Menu from '../Menu/Menu'
import {
  menuOpenSelector,
  getMobileMode,
  setMenuOpen,
  dragEnabledSelector
} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  lateral: {
    zIndex: 20,
    backgroundColor: theme.palette.primary.mainTransparent,
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 0,
    flexShrink: 1,
    // flexGrow: 1,
    maxWidth: theme.sizes.menu.width
  },
  backdrop: { zIndex: 10 }
}))

const Lateral = () => {
  const classes = useStyles()
  const theme = useTheme()
  const open = useSelector(menuOpenSelector)
  const mobileMode = useSelector(getMobileMode)
  const dragEnabled = useSelector(dragEnabledSelector)
  const dispatch = useDispatch()

  const despAnimation = useSpring({
    width:
      open || !mobileMode
        ? theme.sizes.menu.width
        : theme.sizes.menu.widthClosed,
    minWidth:
      open || !mobileMode
        ? theme.sizes.menu.width
        : theme.sizes.menu.widthClosed
  })

  const bindLateral = useGesture({
    onDrag: ({ active, cancel, movement, dragging }) => {
      if (dragEnabled && active && dragging && mobileMode) {
        if (movement[0] > 5) {
          dispatch(setMenuOpen(true))
          cancel()
        } else if (movement[0] < -5) {
          dispatch(setMenuOpen(false))
          cancel()
        }
      }
    }
  })

  const bindBackdrop = useGesture({
    onDragStart: () => {
      if (mobileMode) {
        dispatch(setMenuOpen(false))
      }
    }
  })

  return (
    <>
      <animated.div
        {...bindLateral()}
        style={despAnimation}
        className={classes.lateral}
      >
        <Menu />
      </animated.div>
      <Backdrop
        {...bindBackdrop()}
        className={classes.backdrop}
        open={mobileMode && open}
      ></Backdrop>
    </>
  )
}
export default Lateral
