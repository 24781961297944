import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import FavoriteButton from '../Common/FavoriteButton'
import ShowDetailsButton from '../Common/ShowDetailsButton'
import ImageHouseStateGroup from '../Common/ImageHouseStateGroup'

import {
  columnsSelector,
  getCurrentPageHouses,
  COLUMNS
} from '../../modules/reducerHousesTable'
import { currentHouseSelector } from '../../modules/reducerHouses'

const useStyles = makeStyles({
  root: { padding: '32px', boxSizing: 'border-box' },
  tableRow: {
    '&:hover': {
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    }
  },
  cell: { maxWidth: '100%', whiteSpace: 'nowrap', paddingRight: '28px' },
  heartCell: { width: '30px' }
})

const HousesTableRow = ({ row }) => {
  const columns = useSelector(columnsSelector)
  const currentHouse = useSelector(currentHouseSelector)
  const currentPageHouses = useSelector(getCurrentPageHouses)
  const house = currentPageHouses[row]

  const classes = useStyles({ selected: house && house.id === currentHouse })

  const getCell = (col) => {
    if (col.id === COLUMNS.NAME) {
      return (
        <TableCell key={col.id} className={classes.cell} align={col.align}>
          <ImageHouseStateGroup houseId={house.id} />
        </TableCell>
      )
    } else if (col.id === COLUMNS.FAVORITE) {
      return (
        <TableCell
          key={col.id}
          className={classes.heartCell}
          align={col.align}
          padding={'none'}
        >
          <FavoriteButton houseId={house.id} />
        </TableCell>
      )
    } else if (col.id === COLUMNS.BUTTON) {
      return (
        <TableCell
          key={col.id}
          className={classes.buttonCell}
          align={'center'}
          padding={'none'}
        >
          <ShowDetailsButton houseId={house.id} />
        </TableCell>
      )
    }
    return (
      <TableCell key={col.id} className={classes.cell} align={col.align}>
        <Typography variant="h4">{house[col.id]}</Typography>
      </TableCell>
    )
  }

  return house ? (
    <TableRow className={classes.tableRow}>
      {columns.map((col, idx) => getCell(col))}
    </TableRow>
  ) : null
}
export default HousesTableRow
