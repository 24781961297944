import { createMuiTheme } from '@material-ui/core/styles'
// import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
// const breakpoints = createBreakpoints({})

let lasLomasTheme = createMuiTheme({
  sizes: {
    toolbar: { height: 75 },
    menu: {
      iconWidth: 24,
      width: 240,
      paddingLeft: 24,
      paddingLeftClosed: 12,
      widthClosed: 24 + 2 * 12, //iconWidth+2*paddingLeftClosed
      borderWidth: 4
    }
  },
  typography: {
    fontFamily: `Futura Lt Bt Light ,Roboto, Helvetica, Arial, sans-serif`,
    h1: { fontSize: '24px' },
    h2: { fontSize: '20px' },
    h3: { fontSize: '16px' },
    h4: { fontSize: '14px' },
    h5: { fontSize: '12px' },
    h6: { fontSize: '7px' },

    subtitle1: { fontSize: '16px', textAlign: 'center' }
  },
  palette: {
    primary: {
      main: '#000F31',
      mainTransparent: '#000F31D6',
      light: '#394664'
    },
    secondary: {
      main: '#CFA57E ',
      contrastText: '#FFFFFF'
    },
    other: { background: 'white', detail: '#bdbdbd', greyTable: '#5c667b' }
  }
})

lasLomasTheme.palette = {
  ...lasLomasTheme.palette,
  pdf: { background: lasLomasTheme.palette.other.background },
  gallery: {
    background: lasLomasTheme.palette.other.background,
    barBackground: lasLomasTheme.palette.primary.main + 'A0'
  }
}

lasLomasTheme.props = {
  MuiTooltip: { arrow: true }
}
//
lasLomasTheme.overrides = {
  MuiTooltip: {
    arrow: {
      color: lasLomasTheme.palette.primary.mainTransparent
    },
    tooltip: {
      color: lasLomasTheme.palette.primary.contrastText,
      backgroundColor: lasLomasTheme.palette.primary.mainTransparent
    }
  }
}
// PDFViewerTheme = responsiveFontSizes(PDFViewerTheme)
export default lasLomasTheme
