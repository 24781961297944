const GaleriaIcon = (props) => {
  return (
    <svg viewBox="0 0 27 15" {...props}>
      <path
        fill="fill"
        d="M19.262 5.143c.812 0 1.461-.643 1.461-1.447 0-.803-.649-1.446-1.46-1.446-.812 0-1.461.643-1.461 1.446 0 .804.649 1.447 1.46 1.447z"
      />
      <path
        fill="fill"
        d="M25.81 2.732h-1.948v.536h1.948c.324 0 .649.268.649.643v9.16c0 .322-.27.643-.65.643h-2.001V1.18c0-.643-.541-1.179-1.19-1.179H4.327c-.649 0-1.19.536-1.19 1.179v1.553H1.19A1.194 1.194 0 000 3.911v9.16c0 .643.541 1.179 1.19 1.179h2.056c.109.429.542.75 1.083.75H22.67c.487 0 .92-.321 1.082-.75h2.057c.649 0 1.19-.536 1.19-1.179v-9.16c0-.643-.541-1.179-1.19-1.179zM4.329.536H22.67c.325 0 .596.268.596.643v12.214l-5.52-5.84c-.054-.053-.108-.107-.216-.107a.41.41 0 00-.216.108l-2.976 3.16L9.63 4.982a.415.415 0 00-.216-.107c-.108 0-.162.054-.217.107L3.68 13.18v-12c0-.375.27-.643.65-.643zM.54 13.07V3.91c0-.322.27-.643.65-.643h1.947v10.446H1.19a.655.655 0 01-.649-.643zm22.076 1.393H4.33a.667.667 0 01-.596-.375L9.47 5.571l4.653 5.679c.054.054.108.107.217.107a.407.407 0 00.216-.107l2.976-3.16 5.681 6a.664.664 0 01-.595.374z"
      />
    </svg>
  )
}
export default GaleriaIcon
