const VistaAereaIcon = (props) => {
  return (
    <svg viewBox="0 0 23 21" {...props}>
      <path
        fill="fill"
        d="M21.48.484c-1.038-.944-3.177-.36-4.124.5l-3.79 3.973-1.985-.612c.427-.5.397-1.194-.123-1.638-.275-.25-.61-.362-.977-.362s-.703.14-.978.362l-.856.777-1.16-.36c.274-.362.244-.834-.123-1.167a1.046 1.046 0 00-1.406 0l-.58.527-3.636-1.11-.061.11c-.275.25-.398.556-.398.89 0 .333.153.638.398.888l7.21 6.556-4.46 4.639L0 13.65v2.361l2.23.917c0 .055-.03.11-.03.167 0 .25.092.472.306.638.183.167.427.278.702.278.061 0 .123 0 .184-.028L4.4 20.012h2.597l-.886-4.028 5.133-4.055 7.242 6.583c.245.222.58.361.917.361.336 0 .672-.11.916-.36l.092-.084-1.192-3.333.612-.556c.397-.361.397-.917 0-1.278a1.057 1.057 0 00-1.284-.083l-.366-1.056.855-.777c.275-.25.397-.556.397-.89 0-.333-.152-.638-.397-.888-.489-.445-1.283-.473-1.833-.084L16.56 7.68l4.37-3.472c.947-.834 1.588-2.778.55-3.723zM9.84 3.012c.336-.305.947-.305 1.283 0 .367.333.367.861 0 1.167l-.03.028-1.925-.584.672-.61zm-3.514-.75a.574.574 0 01.764 0c.214.195.214.5 0 .695l-.03.027-1.131-.333.397-.389zm-4.308.695a.766.766 0 01-.092-1.084l6.386 1.945 1.467.444L13.2 5.29 9.197 9.484l-7.18-6.527zm.397 13.583l-1.956-.806v-1.583l3.606.667-1.589 1.639c0 .027-.03.055-.061.083zm4.033 3.056H4.706l-.887-1.778c.031-.028.062-.056.092-.056l1.803-1.416.733 3.25zm12.314-6.028a.572.572 0 01.764 0c.214.194.214.5 0 .694l-.458.417-.367-1.056.061-.055zm-1.344-3.695c.366-.333.947-.333 1.283 0a.768.768 0 01.275.584.727.727 0 01-.275.583l-.672.667-.459-1.25-.183-.528.03-.056zm-.58-.055l.916 2.5 2.17 6a.904.904 0 01-1.1-.084l-7.242-6.583 4.613-3.639.642 1.806zm-13.231 7.61c-.062.056-.123.084-.184.112h-.03c-.184.056-.428.028-.58-.111-.184-.167-.215-.417-.062-.611v-.028c.03-.028.03-.056.061-.083L17.691 1.29c.52-.472 1.498-.861 2.323-.861.428 0 .855.11 1.13.36.795.723.245 2.418-.519 3.14l-17.02 13.5z"
      />
      <path
        fill="fill"
        d="M17.906 1.956l.336.306c.366-.333.947-.333 1.283 0 .367.333.367.861 0 1.167l.336.305c.55-.5.55-1.278 0-1.778a1.527 1.527 0 00-1.955 0z"
      />
    </svg>
  )
}
export default VistaAereaIcon
