import { useRef, useEffect, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'
import mergeRefs from 'react-merge-refs'
import { useGesture } from 'react-use-gesture'

import { drawShape } from '../../utils/draw'
import { rgbaToHex2 } from '../../utils/color'

import { getChildOffset } from '../../utils/centeredChild'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', height: '100%', position: 'relative' },
  canvas: {
    width: '100%',
    height: '100%'
  },
  canvasMask: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0
  }
}))

function SegmentsCanvas({
  scale,
  translation,
  imageSize,
  imageSrc,
  segments,
  drawSegment,
  onFirstLoad,
  onBoundsChanged,
  onDrawStart,
  onDrawEnd,
  onSegmentClick,
  onSegmentHoverChanged
}) {
  const [boundsRef, bounds] = useMeasure()
  const canvasRef = useRef(null)
  const canvasMaskRef = useRef(null)
  const [image, setImage] = useState(null)
  const [hoverSegment, setHoverSegment] = useState('')
  const [firstImageLoaded, setFirstImageLoaded] = useState(false)
  const [repaint, setRepaint] = useState(false)
  const [frameId, setFrameId] = useState(null)
  const hasSegments = segments && segments.length > 0
  const classes = useStyles()

  const drawMask = useCallback(
    (offset) => {
      if (hasSegments) {
        const canvasMask = canvasMaskRef.current
        const ctxMask = canvasMask.getContext('2d')
        ctxMask.canvas.width = bounds.width
        ctxMask.canvas.height = bounds.height
        ctxMask.setTransform(scale, 0, 0, scale, offset.x, offset.y)
        for (let segment of segments) {
          drawShape(ctxMask, segment.polygons, true, segment.color)
        }
      }
    },
    [hasSegments, segments, bounds, scale]
  )

  const drawScene = useCallback(
    (offset) => {
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')
      ctx.canvas.width = bounds.width
      ctx.canvas.height = bounds.height
      ctx.setTransform(scale, 0, 0, scale, offset.x, offset.y)
      //Imagen
      ctx.drawImage(image, 0, 0, imageSize.width, imageSize.height)
      //Segments
      if (hasSegments && drawSegment) {
        for (let segment of segments) {
          drawSegment(ctx, segment)
        }
      }
      //
    },
    [imageSize, image, scale, bounds, hasSegments, segments, drawSegment]
  )

  const draw = useCallback(() => {
    if (
      (hasSegments && !canvasMaskRef.current) ||
      !canvasRef.current ||
      !bounds ||
      !image ||
      image.src2 !== imageSrc
    ) {
      return
    }
    //Desplazamiento para centrar imagen
    const offset = getChildOffset(scale, translation, imageSize, bounds)
    drawMask(offset)
    drawScene(offset)
    setFrameId(null)
    if (onDrawEnd) {
      onDrawEnd()
    }
    console.log('draw')
  }, [
    scale,
    translation,
    image,
    imageSize,
    drawMask,
    drawScene,
    bounds,
    imageSrc,
    onDrawEnd,
    hasSegments
  ])

  useEffect(() => {
    if (onBoundsChanged) {
      onBoundsChanged(bounds)
    }
    setRepaint(true)
    if (onDrawStart) {
      onDrawStart()
    }
  }, [bounds, onBoundsChanged, onDrawStart])

  useEffect(() => {
    setRepaint(true)
    if (onDrawStart) {
      onDrawStart()
    }
  }, [scale, translation, onDrawStart])

  // Cambio de imagen
  useEffect(() => {
    if (!image || image.src2 !== imageSrc) {
      if (onDrawStart) {
        onDrawStart()
      }
      const img = new Image()
      img.onload = () => {
        if (image) {
          image.src = ''
          image.onload = null
        }
        setImage(img)
        setRepaint(true)
      }

      img.src = imageSrc
      img.src2 = imageSrc //Esta por la ruta que devuelve img.src. No es igual que imageSrc
    }
  }, [imageSrc, image, onDrawStart])

  useEffect(() => {
    if (repaint) {
      setRepaint(false)
      if (frameId) {
        window.cancelAnimationFrame(frameId)
        console.log('cancel')
      }
      setFrameId(window.requestAnimationFrame(draw))
    }
  }, [repaint, frameId, draw])

  useEffect(() => {
    if (image && bounds && bounds.width > 0 && bounds.height > 0) {
      if (!firstImageLoaded) {
        setFirstImageLoaded(true)
        if (onFirstLoad) {
          onFirstLoad(bounds)
        }
      }
    }
  }, [image, firstImageLoaded, bounds, onFirstLoad])

  //Color del pixel en la mascara actual
  const getPixelColor = useCallback(
    (x, y) => {
      const canvasMask = canvasMaskRef.current
      const ctxMask = canvasMask.getContext('2d')
      const colorData = ctxMask.getImageData(
        x - bounds.left,
        y - bounds.top,
        1,
        1
      ).data
      return rgbaToHex2(colorData[0], colorData[1], colorData[2], colorData[3])
    },
    [bounds]
  )

  const getSegmentAtPoint = useCallback(
    (x, y) => {
      if (hasSegments) {
        const hexColor = getPixelColor(x, y)
        const segment = segments.find((segment) => segment.color === hexColor)
        return segment ? hexColor : ''
      }
      return ''
    },
    [getPixelColor, segments, hasSegments]
  )

  const bind = useGesture({
    onDragStart: ({ xy }) => {
      const segment = getSegmentAtPoint(xy[0], xy[1])
      if (onSegmentClick) {
        onSegmentClick(segment)
        setRepaint(true)
      }
    },
    onMove: ({ xy }) => {
      if (onSegmentHoverChanged) {
        const segment = getSegmentAtPoint(xy[0], xy[1])
        if (hoverSegment !== segment) {
          setHoverSegment(segment)
          onSegmentHoverChanged(segment)
          setRepaint(true)
        }
      }
    }
  })

  return (
    <div {...bind()} className={classes.root}>
      <canvas
        className={classes.canvas}
        ref={mergeRefs([canvasRef, boundsRef])}
      />
      {hasSegments ? (
        <canvas className={classes.canvasMask} ref={canvasMaskRef} />
      ) : null}
    </div>
  )
}
export default SegmentsCanvas
