import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import HeartIcon from '../Icons/HeartIcon'
import { favoritesSelector, toggleFavorite } from '../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    width: 16,
    height: 16,
    fill: ({ isFavorite }) =>
      isFavorite ? 'red' : theme.palette.other.greyTable
  }
}))

const FavoriteButton = ({ houseId, ...props }) => {
  const favorites = useSelector(favoritesSelector)
  const isFavorite = favorites.has(houseId)
  const classes = useStyles({ isFavorite })
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(toggleFavorite(houseId))
    },
    [dispatch, houseId]
  )

  return (
    <IconButton onClick={handleClick} {...props}>
      <HeartIcon className={classes.icon} filled={isFavorite} />
    </IconButton>
  )
}
export default FavoriteButton
