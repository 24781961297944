import { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import LabelRadio from '../Common/LabelRadio'
import TwoButtons from '../Common/TwoButtons'
import StateColor from '../Common/StateColor'
import HeartIcon from '../Icons/HeartIcon'
import HouseDetailsList from '../Common/HouseDetailsList'
import DocumentsGallery from './DocumentsGallery'

import {
  getHouseDetails,
  currentHouseSelector
} from '../../modules/reducerHouses'

import {
  houseDetailsPlano3DModeSelector,
  setPlano3dModeMode
} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    color: theme.palette.primary.contrastText,
    marginLeft: theme.sizes.menuPaddingLeft,
    marginTop: 12
  },
  icon: {
    width: 12,
    height: 12,
    fill: ({ isFavorite }) =>
      isFavorite ? 'red' : theme.palette.primary.contrastText,
    paddingRight: 4
  },
  textTop: {
    textTransform: 'uppercase'
  },
  titleWrapper: { paddingBottom: 20 },
  nameWrapper: { display: 'flex', flexFlow: 'row', alignItems: 'center' },
  textBottom: {
    margin: '32px 0 16px 0'
  },
  radioGroup: { paddingTop: 16 }
}))

const HouseDetails = () => {
  const currentHouse = useSelector(currentHouseSelector)
  const gHouseDetails = useMemo(getHouseDetails, [])
  const houseDetails = useSelector((state) =>
    gHouseDetails(state, currentHouse)
  )

  const isFavorite = houseDetails ? houseDetails.favorite : false
  const plano3DMode = useSelector(houseDetailsPlano3DModeSelector)
  const classes = useStyles({ isFavorite })
  const dispatch = useDispatch()
  // const handleChange = (event) => {
  //   setValue(event.target.value)
  // }
  const handleModeClick = useCallback(
    (side) => {
      dispatch(setPlano3dModeMode(side === 'left'))
    },
    [dispatch]
  )

  return houseDetails.house ? (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <div className={classes.nameWrapper}>
          <HeartIcon className={classes.icon} filled={isFavorite} />
          <Typography variant="h3" className={classes.textTop}>
            {houseDetails.house.name}
          </Typography>
        </div>
        <StateColor stateId={houseDetails.house.state} />
      </div>
      <HouseDetailsList houseId={currentHouse} />
      <Typography variant="h3" className={classes.textBottom}>
        Selecciona tipo de vista
      </Typography>
      <TwoButtons
        leftText={'Plano 3D'}
        rightText={'Visita virtual'}
        leftSelected={plano3DMode}
        width={190}
        onClick={handleModeClick}
      />
      <RadioGroup className={classes.radioGroup} value={0}>
        <LabelRadio label={'Amueblado'} value={0} />
        <LabelRadio label={'Vacío'} value={1} />
        <LabelRadio label={'Giratorio'} value={2} />
      </RadioGroup>
      <DocumentsGallery />
    </div>
  ) : null
}
export default HouseDetails
