import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import StateColor from '../Common/StateColor'

import { getHouse } from '../../modules/reducerHouses'

const useStyles = makeStyles({
  root: { display: 'flex', flexFlow: 'row' },
  houseImg: {
    width: '68px',
    height: '46px',
    padding: '0 10px',
    objectFit: 'cover'
  },
  houseNameWrapper: { display: 'flex', flexFlow: 'column' }
})

const ImageHouseStateGroup = ({ houseId }) => {
  const gHouse = useMemo(getHouse, [])
  const house = useSelector((state) => gHouse(state, houseId))

  const classes = useStyles()

  return house ? (
    <div className={classes.root}>
      <img className={classes.houseImg} src={house.img} alt={'imagen'} />
      <div className={classes.houseNameWrapper}>
        <Typography variant="h4">{house.name}</Typography>
        <StateColor stateId={house.state} />
      </div>
    </div>
  ) : null
}
export default ImageHouseStateGroup
