import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'

import { getState } from '../../modules/reducerHouses'

const useStyles = makeStyles({
  stateWrapper: { display: 'flex', flexFlow: 'row', alignItems: 'center' },
  colorMark: { width: '4px', height: '10px' },
  stateName: { fontSize: '12px' }
})

const StateColor = ({ stateId, aditionalText }) => {
  const classes = useStyles()
  const gState = useMemo(getState, [])
  const state = useSelector((state) => gState(state, stateId))

  return state ? (
    <div className={classes.stateWrapper}>
      <div
        className={classes.colorMark}
        style={{ backgroundColor: state.color }}
      />
      <Typography variant="h5">
        {state.name + (aditionalText ? aditionalText : '')}
      </Typography>
    </div>
  ) : null
}
export default StateColor
