import { useCallback, useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useSelector, useDispatch } from 'react-redux'
import RadioGroup from '@material-ui/core/RadioGroup'
import LabelRadio from '../Common/LabelRadio'

import {
  getGallery,
  currentGallerySelector,
  setCurrentGallery
} from '../../modules/reducerGallery'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.sizes.menuPaddingLeft
    //
  },
  title: { margin: '0 0 12px 0' }
}))

const GalleryItem = ({ id }) => {
  const gGallery = useMemo(getGallery, [])
  const gallery = useSelector((state) => gGallery(state, id))
  return gallery ? (
    <LabelRadio
      style={{ padding: '4px 0px' }}
      label={gallery.name}
      value={id}
    />
  ) : null
}

const GalleriesMenu = ({ galleriesIds }) => {
  // const galleries = useSelector(galleriesSelector)
  const currentGallery = useSelector(currentGallerySelector)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    if (galleriesIds && galleriesIds.length > 0) {
      dispatch(setCurrentGallery(galleriesIds[0]))
    }
  }, [dispatch, galleriesIds])

  const handleChange = useCallback(
    (e) => {
      dispatch(setCurrentGallery(e.target.value))
    },
    [dispatch]
  )

  return galleriesIds && galleriesIds.length ? (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Selecciona las imágenes que deseas ver
      </Typography>
      <RadioGroup value={currentGallery} onChange={handleChange}>
        {galleriesIds.map((galleryId, index) => (
          <GalleryItem key={index} id={galleryId} />
        ))}
      </RadioGroup>
    </div>
  ) : null
}
export default GalleriesMenu
