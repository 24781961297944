import { useState } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 1
  },
  popperWithArrow: {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  }
}))

const SegmentHotspotPopper = ({
  anchorRef,
  boundariesRef,
  open,
  children,
  arrow,
  placement,
  offset
}) => {
  const classes = useStyles({ arrow })
  const [arrowRef, setArrowRef] = useState(null)
  return (
    <Popper
      className={`${classes.popper} ${arrow ? classes.popperWithArrow : ''}`}
      placement={placement ? placement : 'top'}
      open={open}
      anchorEl={anchorRef.current}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: boundariesRef.current
        },
        flip: {
          enabled: true
        },
        arrow: {
          enabled: arrow,
          element: arrowRef
        },
        keepTogether: {
          enabled: true
        },
        offset: {
          enabled: offset,
          offset: `${offset ? offset.x : 0}px,${offset ? offset.y : 0}px`
        }
      }}
    >
      {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
      {children}
    </Popper>
  )
}
export default SegmentHotspotPopper
