import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import HousesTable from '../HousesTable/HousesTable'
import HousesGallery from '../HousesGallery/HousesGallery'
import HousesRotator from '../HousesRotator/HousesRotator'
import GeneralView from '../GeneralView/GeneralView'
import Mapa from '../Map/Map'
import AerialView from '../AerialView/AerialView'
import PersonView from '../PersonView/PersonView'
import Plano3D from '../Plano3D/Plano3D'
import VisitaVirtual from '../VisitaVirtual/VisitaVirtual'
import PdfViewer from '../PDFViewer/PDFViewer'
import Gallery from '../Gallery/Gallery'
import GalleryMobile from '../Gallery/GalleryMobile'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import VirtualTour from '../VirtualTour/VirtualTour'

import {
  currentMenuOptionSelector,
  currentSubmenuSelector,
  filterListViewModeSelector,
  houseDetailsPlano3DModeSelector,
  getMobileMode,
  menuOpenSelector,
  MENU_OPTIONS,
  SUBMENUS
} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0
  },
  rootSmall: {
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 0,
    paddingLeft: ({ menuOpen }) =>
      menuOpen ? 0 : theme.sizes.menu.widthClosed,
    boxSizing: 'border-box',
    width: '100%',
    height: `calc(100% - ${theme.sizes.toolbar.height}px)`
  }
}))

const Center = () => {
  const menuOpen = useSelector(menuOpenSelector)
  const mobileMode = useSelector(getMobileMode)
  const currentSubmenu = useSelector(currentSubmenuSelector)
  const currentMenuOption = useSelector(currentMenuOptionSelector)
  const filterListViewMode = useSelector(filterListViewModeSelector)
  const houseDetailsPlano3DMode = useSelector(houseDetailsPlano3DModeSelector)
  const classes = useStyles({ menuOpen })

  const getCenter = () => {
    if (currentSubmenu === SUBMENUS.DETALLES_CASA) {
      return houseDetailsPlano3DMode ? <Plano3D /> : <VisitaVirtual />
    } else if (currentSubmenu === SUBMENUS.DOCUMENTOS_VIVIENDA) {
      return <PdfViewer mobile={mobileMode} />
    } else if (currentSubmenu === SUBMENUS.GALERIAS_VIVIENDA) {
      return mobileMode ? <GalleryMobile /> : <Gallery />
    } else if (currentMenuOption === MENU_OPTIONS.PLANO_SITUACION) {
      return <Mapa />
    } else if (currentMenuOption === MENU_OPTIONS.VISTA_AEREA) {
      return <AerialView />
    } else if (currentMenuOption === MENU_OPTIONS.VISITAS_VIRTUALES) {
      return <VirtualTour />
    } else if (currentMenuOption === MENU_OPTIONS.VISTA_PEATONAL) {
      return <PersonView />
    } else if (currentMenuOption === MENU_OPTIONS.SELECCIONA_VIVIENDA) {
      if (filterListViewMode) {
        return mobileMode ? <HousesGallery /> : <HousesTable />
      } else {
        return <HousesRotator />
      }
    } else if (currentMenuOption === MENU_OPTIONS.DOCUMENTOS) {
      return <PdfViewer mobile={mobileMode} />
    } else if (currentMenuOption === MENU_OPTIONS.GALERIA_IMAGENES) {
      return mobileMode ? <GalleryMobile /> : <Gallery />
    } else if (currentMenuOption === MENU_OPTIONS.VIDEOS) {
      return <VideoPlayer master={true} />
    }

    return <GeneralView />
  }

  return (
    <div className={mobileMode ? classes.rootSmall : classes.root}>
      {getCenter()}
    </div>
  )
}
export default Center
