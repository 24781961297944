// import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getFilterPois } from '../../modules/reducerMap'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: theme.palette.other.backgroundColor
  }
}))

const Map = () => {
  const classes = useStyles()
  const pois = useSelector(getFilterPois)
  return (
    <div className={classes.root}>
      <div>
        MAPA
        {pois.map((poi, idx) => (
          <div key={idx}>
            {poi.name +
              ' ' +
              poi.subcategory +
              ' ' +
              poi.longitud +
              ' ' +
              poi.latitud +
              ' ' +
              poi.address +
              ' ' +
              poi.content}
          </div>
        ))}
      </div>
    </div>
  )
}
export default Map
