import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import LabelCheckBox from '../Common/LabelCheckBox'
import Title from './Title'
import { houseTypologiesSelector } from '../../modules/reducerHouses'
import {
  typologySelector,
  selectTypology,
  getClassifyHousesByTypology
} from '../../modules/reducerHousesFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // height: '45px',
    display: 'flex',
    flexFlow: 'column'
  }
}))

const TypologyItem = ({ id, name }) => {
  const selectedTypologies = useSelector(typologySelector)
  const housesByTypology = useSelector(getClassifyHousesByTypology)
  const typo = housesByTypology.find((v) => v.typologyId === id)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (e) => {
      dispatch(selectTypology(id, e.target.checked))
    },
    [id, dispatch]
  )

  return (
    <LabelCheckBox
      label={name + ` (${typo ? typo.housesId.length : 0})`}
      checked={selectedTypologies.has(id)}
      onChange={handleChange}
    />
  )
}

const Typology = () => {
  const classes = useStyles()
  const typologies = useSelector(houseTypologiesSelector)

  return typologies.length > 0 ? (
    <div className={classes.root}>
      <Title text={'Tipologias'} />
      {typologies.map((typo) => {
        return <TypologyItem key={typo.id} id={typo.id} name={typo.name} />
      })}
    </div>
  ) : null
}
export default Typology
