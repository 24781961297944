import { useRef, useEffect, useCallback, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import PlaceIcon from '@material-ui/icons/Place'
import SegmentHotspotPopper from './SegmentHotspotPopper'

import { getSegment } from '../../modules/reducerRotatorGroups'
import {
  localPointToGlobalPoint,
  getChildTopLeft
} from '../../utils/centeredChild'
import { isPointInsideBounds } from '../../utils/mathFunctions'
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    transform: 'translate(-50%,-100%)',
    left: ({ position }) => position.x,
    top: ({ position }) => position.y
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '2em',
    '&:hover': { fontSize: '3em', color: theme.palette.secondary.main }
  }
}))

function SegmentHotspot({
  parentBounds,
  childSize,
  segmentId,
  poiId, //Nombre del poi a usar como centro
  scale, //Escala aplicada a la imagen
  translation, //Desplazamiento a plicado a la imagen
  update, //Se puede cambiar la posicion del poi. Si se esta moviendo la imagen
  //los hostspot se dibujan antes y parece que no estan sincronizados. Update se
  //activa cuando la imagen ha terminado de dibujarse y entonces es cuando se
  //actualiza la posicion del hotspot y se dibuja.
  children //Contenido del popper
}) {
  const [localScale, setLocalScale] = useState(scale)
  const [localTranslation, setLocalTranslation] = useState(translation)
  const [globalPoint, setGlobalPoint] = useState({ x: 0, y: 0 })
  const [pointVisible, setPointVisible] = useState(true)
  const [hover, setHover] = useState(false)
  const rootRef = useRef(null)
  const gSegment = useMemo(getSegment, [])
  const segment = useSelector((state) => gSegment(state, segmentId))

  const localPoi = segment ? segment.pois.find((poi) => poi.id === poiId) : null
  const classes = useStyles({ position: globalPoint })

  useEffect(() => {
    if (update) {
      setLocalScale(scale)
      setLocalTranslation(translation)
    }
  }, [scale, translation, update])

  useEffect(() => {
    const topLeft = getChildTopLeft(
      localScale,
      localTranslation,
      childSize,
      parentBounds
    )
    const point = localPointToGlobalPoint(
      {
        x: localPoi ? localPoi.point[0] : 0,
        y: localPoi ? localPoi.point[1] : 0
      },
      localScale,
      topLeft
    )
    setGlobalPoint(point)
    setPointVisible(isPointInsideBounds(point.x, point.y, parentBounds))
  }, [parentBounds, childSize, localScale, localTranslation, localPoi])

  const handleMouseOver = useCallback(() => {
    setHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])
  return localPoi && pointVisible ? (
    <div
      ref={rootRef}
      className={classes.root}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <PlaceIcon className={classes.icon} />
      <SegmentHotspotPopper
        anchorRef={rootRef}
        boundariesRef
        open={hover}
        placement={'top'}
        offset={{ x: 0, y: 0 }}
      >
        {children}
      </SegmentHotspotPopper>
    </div>
  ) : null
}

export default SegmentHotspot
