import { useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import MenuItemMain from './MenuItemMain'
import VideosMenu from '../Videos/VideosMenu'
import DownloadsMenu from '../Downloads/DownloadsMenu'

import {
  getMenuOption,
  menuOpenSelector,
  SUBMENUS,
  optionClicked,
  getMenuOptionExpanded
} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 0
    },
    backgroundColor: 'transparent'
  },
  expanded: {},
  focused: {},
  summary: {
    backgroundColor: 'transparent', // theme.palette.primary.mainTransparent,
    minHeight: 0,
    padding: 0,
    '&$expanded': {
      minHeight: 0
    },
    '&$focused': { backgroundColor: theme.palette.primary.mainTransparent }
  },
  summaryContent: {
    margin: 0,
    width: '100%',
    '&$expanded': {
      margin: 0
    },
    backgroundColor: 'transparent'
  },
  details: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexFlow: 'column',
    padding: 0
  }
}))

const MenuItemExpandable = ({ optionId }) => {
  const classes = useStyles()
  const gMenuOption = useMemo(getMenuOption, [])
  const option = useSelector((state) => gMenuOption(state, optionId))
  const gMenuOptionExpanded = useSelector(getMenuOptionExpanded)
  const open = useSelector(menuOpenSelector)
  const expanded = gMenuOptionExpanded(optionId) && open
  const dispatch = useDispatch()

  const getExpandContent = () => {
    if (option.expandable) {
      if (option.submenu === SUBMENUS.VIDEOS) {
        return <VideosMenu />
      } else if (option.submenu === SUBMENUS.DESCARGAS) {
        return <DownloadsMenu />
      }
      return <div style={{ height: '200px' }}></div>
    } else return null
  }

  const handleChange = useCallback(
    (e) => {
      dispatch(optionClicked(optionId))
    },
    [dispatch, optionId]
  )

  return option ? (
    <Accordion
      classes={{
        root: classes.root,
        expanded: classes.expanded
      }}
      expanded={expanded}
      square
      onChange={handleChange}
    >
      <AccordionSummary
        className={classes.summary}
        classes={{
          content: classes.summaryContent,
          expanded: classes.expanded,
          focused: classes.focused
        }}
      >
        <MenuItemMain optionId={optionId} />
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {getExpandContent()}
      </AccordionDetails>
    </Accordion>
  ) : null
}

export default MenuItemExpandable
