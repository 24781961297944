const PlanoSituacionIcon = (props) => {
  return (
    <svg viewBox="0 0 23 18" {...props}>
      <path
        fill="fill"
        d="M21.304 0H1.727C.767 0 0 .804 0 1.81v14.38C0 17.195.768 18 1.727 18h19.545c.96 0 1.727-.805 1.727-1.81V1.81C23.031.804 22.263 0 21.304 0zm1.247 16.224c0 .737-.576 1.307-1.247 1.307H1.727c-.703 0-1.247-.604-1.247-1.307V1.81c0-.737.576-1.307 1.247-1.307h19.545c.703 0 1.247.603 1.247 1.307v14.414h.032z"
      />
      <path
        fill="fill"
        d="M1.823 15.754h19.385V1.911H1.823v13.843zm2.911-9.687L2.271 8.648V5.531c.032 0 .096-.034.128-.067l.864-.905 1.471 1.508zM2.304 4.86V3.52l.639.67-.64.67zm0 8.18c.031 0 .095-.034.127-.068l.608-.637 1.727 1.81-1.055 1.106H2.303V13.04zm2.526-2.582l1.727 1.81-1.471 1.542L3.359 12l1.471-1.542zm5.822 1.81l-1.695 1.777-1.728-1.81 1.728-1.81 1.695 1.843zm-.576 2.983l-.8-.838 1.728-1.81 2.527 2.648h-3.455zm-3.583 0l-1.055-1.106 1.471-1.542 1.728 1.81-.8.838H6.493zm-.703 0H4.35l.704-.737.736.737zm3.167-.502l.447.469h-.927l.48-.47zm-2.08-2.85L5.15 10.09l1.727-1.81 1.728 1.81-1.728 1.81zm13.5 3.352l-2.112-2.212a.235.235 0 00.128-.067l1.023-1.072c.032-.034.064-.068.064-.135l1.152 1.207a.235.235 0 00.128.067v2.212h-.384zm-1.312-4.625l1.663-1.743v3.52l-1.663-1.777zm1.663-2.38a.235.235 0 00-.128.067l-.864.905-1.375-1.441c-.032-.034-.064-.067-.128-.067l2.463-2.581v3.117h.032zm-2.751-.235c0 .034.032.1.064.134l1.375 1.442-.863.871a.272.272 0 000 .369l.64.67c-.033 0-.096.034-.128.067l-1.024 1.073c-.032.033-.064.067-.064.134l-2.271-2.38 2.271-2.38zm1.056-5.598l-.32.336-.32-.336h.64zm-2.207 2.28l-3.263 3.419L12.09 6.57l1.376-1.441 1.887-1.978 1.472 1.542zm-6.238-2.246l2.399 2.514-1.216 1.274-3.646-3.822h2.43s0 .034.033.034zm4.094 0l.352.369-1.727 1.81-2.111-2.213h3.454c.032 0 .032.034.032.034zm-7.229-.034c0 .034.032.034.032.034l5.918 6.201c.032.067.096.067.16.067s.128-.033.16-.067l3.614-3.788c.032-.033.064-.1.064-.167s-.032-.134-.064-.168l-1.791-1.877-.224-.235h2.431c0 .034.032.034.032.034l.768.804c.032.034.096.067.16.067s.128-.033.16-.067l.767-.804.032-.034h1.056v2.045c-.032 0-.096.034-.128.067l-5.406 5.665a.272.272 0 000 .369l4.478 4.692h-5.47l-5.086-5.33L7.07 7.778a.242.242 0 00-.352 0l-4.414 4.625V9.285c.032 0 .096-.034.128-.067l2.815-2.95c.032-.033.064-.1.064-.167s-.032-.134-.064-.168L3.455 4.056 2.43 2.916c-.032-.033-.096-.067-.128-.067v-.436h5.15zm4.99 13.978H10.62v.503h1.823v-.503z"
      />
      <path
        fill="fill"
        d="M9.468 8.413c.256 0 .512-.1.672-.301a.988.988 0 00.288-.704c0-.268-.096-.537-.288-.704-.352-.369-.992-.369-1.375 0a.988.988 0 00-.288.704c0 .268.096.536.288.704a.97.97 0 00.703.301zM9.116 7.04a.47.47 0 01.352-.134c.128 0 .256.067.352.134.096.067.128.235.128.369s-.064.268-.128.369c-.192.2-.512.2-.704 0a.52.52 0 01-.128-.37.52.52 0 01.128-.368z"
      />
    </svg>
  )
}
export default PlanoSituacionIcon
