import { createSelector } from 'reselect'
import { dataSelector, DATA_TYPE, MEDIA_TYPE } from './reducerRead'
import { videosSelector } from './reducerVideos'
import { findById } from '../utils/utils'

export const SET_PROMOCION = 'SET_PROMOCION'

const initialState = {
  // units: { area: 'm²', currency: '€' }
  currentPromocion: ''
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_PROMOCION:
      if (state.currentPromocion !== action.id) {
        return { ...state, currentPromocion: action.id }
      }
      return state
    default:
      return state
  }
}
export default changeState

export const setPromocion = (id) => ({
  type: SET_PROMOCION,
  id
})

export const setPromocionByName = (name) => (dispatch, getState) => {
  const state = getState()
  console.log(promocionesSelector(state))
  const promo = promocionesSelector(state).find((p) => p.name === name)
  if (promo) {
    dispatch({
      type: SET_PROMOCION,
      id: promo.id
    })
  }
}

export const currentPromocionSelector = (state) =>
  state.promocion.currentPromocion
export const promocionesSelector = createSelector([dataSelector], (data) => {
  const promociones = findById(data, DATA_TYPE.PROMOCION)
  return promociones
    ? promociones.data.map((promo) => {
        let galleries = promo.acf.galerias ? promo.acf.galerias : []
        galleries = galleries.map((galleryId) => DATA_TYPE.GALLERY + galleryId)
        let documents = promo.acf.pdfs_relation ? promo.acf.pdfs_relation : []
        documents = documents.map((docId) => MEDIA_TYPE.PDF + docId)
        let videos = promo.acf.videos_relation ? promo.acf.videos_relation : []
        videos = videos.map((videoId) => MEDIA_TYPE.VIDEO + videoId)
        return {
          id: DATA_TYPE.PROMOCION + promo.id,
          name: promo.title.rendered,
          areaUnit: promo.acf.superficie ? promo.acf.superficie : 'm²',
          currency: promo.acf.moneda ? promo.acf.moneda : '€',
          address: promo.acf.direccion ? promo.acf.direccion : '',
          latitude: promo.acf.latitud ? parseFloat(promo.acf.latitud) : 0,
          longitude: promo.acf.longitud ? parseFloat(promo.acf.longitud) : 0,
          galleries,
          documents,
          videos
        }
      })
    : []
})

export const getCurrentPromocion = createSelector(
  [promocionesSelector, currentPromocionSelector],
  (promociones, currentPromocion) => {
    return findById(promociones, currentPromocion)
  }
)

export const getCurrentPromocionVideos = createSelector(
  [getCurrentPromocion, videosSelector],
  (promocion, videos) => {
    if (!promocion) {
      return []
    }
    return videos.filter((video) => promocion.videos.indexOf(video.id) >= 0)
  }
)

export const getCurrentPromocionDocuments = createSelector(
  [getCurrentPromocion, videosSelector],
  (promocion, videos) => {
    if (!promocion) {
      return []
    }
    return videos.filter((video) => promocion.videos.indexOf(video.id) >= 0)
  }
)
