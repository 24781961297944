import { createSelector } from 'reselect'
import { dataSelector, DATA_TYPE, MEDIA_TYPE } from './reducerRead'
import { findById } from '../utils/utils'

export const ADD_DOWNLOAD = 'ADD_DOWNLOAD'
export const REMOVE_DOWNLOAD = 'REMOVE_DOWNLOAD'

const initialState = {
  download: new Set(),
  // documents: [],
  documents: [
    {
      id: 'PDF543',
      name: 'Documento numero 0',
      src: 'pdf/Catalogo-Terrazos-bj.pdf'
    },
    {
      id: 'PDF541',
      name: 'Documento numero 2',
      src: 'pdf/catálogo-ventanas-rehau.pdf'
    },
    {
      id: 'd3',
      name: 'Documento numero 3',
      src: 'pdf/ENTREGAS-INMEDIATA-DE-MUEBLES-ECONOMICOS.pdf'
    }
  ]
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case ADD_DOWNLOAD: {
      const download = new Set(state.download)
      download.add(action.id)
      return {
        ...state,
        download
      }
    }
    case REMOVE_DOWNLOAD: {
      const download = new Set(state.download)
      download.delete(action.id)
      return {
        ...state,
        download
      }
    }
    default:
      return state
  }
}
export default changeState

export const addDownload = (id) => ({
  type: ADD_DOWNLOAD,
  id
})

export const removeDownload = (id) => ({
  type: REMOVE_DOWNLOAD,
  id
})

// export const documentsSelector = (state) => state.documents.documents

export const documentsSelector = createSelector([dataSelector], (data) => {
  const media = findById(data, DATA_TYPE.MEDIA)
  return media
    ? media.data.reduce((acc, doc) => {
        if (doc.mime_type === 'application/pdf') {
          acc.push({
            id: MEDIA_TYPE.PDF + doc.id,
            name: doc.title.rendered,
            src: doc.source_url
          })
        }
        return acc
      }, [])
    : []
})

export const downloadSelector = (state) => [...state.documents.download]

export const getDocument = () =>
  createSelector([documentsSelector, (_, id) => id], (documents, id) => {
    return findById(documents, id)
  })

export const getDocumentBySrc = () =>
  createSelector([documentsSelector, (_, src) => src], (documents, src) => {
    return documents.find((v) => v.src === src)
  })

export const getDocuments = createSelector(
  [documentsSelector],
  (documents) => (ids) => {
    return ids ? documents.filter((doc) => ids.indexOf(doc.id) >= 0) : []
  }
)
