// import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import GalleryThumbnails from './GalleryThumbnails'

import { getCurrentGallery } from '../../modules/reducerGallery'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.gallery.barBackground,

    zIndex: 10,
    position: 'absolute',
    bottom: 0,

    width: '100%',
    boxSizing: 'border-box',
    padding: 10
  }
}))

const GalleryBar = () => {
  const classes = useStyles()
  const currentGallery = useSelector(getCurrentGallery)

  return currentGallery ? (
    <div className={classes.root}>
      <GalleryThumbnails />
    </div>
  ) : null
}
export default GalleryBar
