import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import HeartIcon from '../Icons/HeartIcon'

import { favoritesSelector } from '../../modules/reducerHouses'
import {
  favoritesOnSelector,
  activeFavorites
} from '../../modules/reducerHousesFilter'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    textTransform: 'none',
    marginTop: 16,
    color: theme.palette.primary.contrastText,
    justifyContent: 'start',
    padding: 0,
    fill: ({ favoritesOn }) =>
      favoritesOn ? 'red' : theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.secondary.main,
      fill: ({ favoritesOn }) =>
        favoritesOn ? 'red' : theme.palette.secondary.main
    }
    // display: 'flex',
    // flexFlow: 'column'
  },
  icon: {
    width: 16,
    height: 16,
    paddingRight: 4
  }
}))

const Favorites = () => {
  const favoritesOn = useSelector(favoritesOnSelector)
  const favorites = useSelector(favoritesSelector)
  const classes = useStyles({ favoritesOn })
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (e) => {
      dispatch(activeFavorites(!favoritesOn))
    },
    [dispatch, favoritesOn]
  )

  return (
    <Button disableRipple className={classes.button} onClick={handleClick}>
      <HeartIcon className={classes.icon} filled={favoritesOn} />
      <Typography variant="h3" className={classes.title}>
        Favoritos({favorites.size})
      </Typography>
    </Button>
  )
}
export default Favorites
