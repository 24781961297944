import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import HomeIcon from '../Icons/HomeIcon'
import VolverIcon from '../Icons/VolverIcon'

import {
  homeClicked,
  backClicked,
  menuOpenSelector
} from '../../modules/reducerMain'

const BAR_HEIGHT = 56
const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    width: theme.sizes.menu.width,
    height: BAR_HEIGHT,
    minHeight: BAR_HEIGHT,
    display: 'flex',
    flexFlow: 'row',
    paddingBottom: 20
  },
  button: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    fill: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.main,
      fill: theme.palette.secondary.main
    },
    borderRadius: 0,
    width: '50%',
    padding: 0
  },
  buttonLabel: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    height: '100%',
    position: 'relative'
  },
  title: {
    textTransform: 'uppercase',
    // color: theme.palette.primary.contrastText,
    color: 'inherit',
    position: 'absolute',
    bottom: 0
  },
  icon: {
    width: '16px',
    height: 'auto',
    fill: 'inherit'
    // fill: theme.palette.primary.contrastText,
    // '&:hover': { fill: theme.palette.secondary.main }
  },
  closed: {
    paddingTop: 12,
    paddingBottom: 20,

    boxSizing: 'border-box',
    height: BAR_HEIGHT,
    width: '100%',
    textAlign: 'center'
  },
  iconClosed: {
    width: '24px',
    height: '100%',
    fill: theme.palette.primary.contrastText,
    '&:hover': { fill: theme.palette.secondary.main }
  }
}))

const SubmenuBar = () => {
  const menuOpen = useSelector(menuOpenSelector)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleHomeClick = useCallback(() => {
    dispatch(homeClicked())
  }, [dispatch])

  const handleBackClick = useCallback(() => {
    dispatch(backClicked())
  }, [dispatch])

  return menuOpen ? (
    <div className={classes.root}>
      <Button
        variant="contained"
        disableFocusRipple
        disableRipple
        disableElevation
        classes={{ root: classes.button, label: classes.buttonLabel }}
        style={{ borderRight: '1px solid white' }}
        onClick={handleHomeClick}
      >
        <HomeIcon className={classes.icon} />
        <Typography className={classes.title} variant={'h6'}>
          Home
        </Typography>
      </Button>
      <Button
        variant="contained"
        disableFocusRipple
        disableRipple
        disableElevation
        classes={{ root: classes.button, label: classes.buttonLabel }}
        onClick={handleBackClick}
      >
        <VolverIcon className={classes.icon} />
        <Typography className={classes.title} variant={'h6'}>
          Volver
        </Typography>
      </Button>
    </div>
  ) : (
    <div className={classes.closed} onClick={handleHomeClick}>
      <HomeIcon className={classes.iconClosed} />
    </div>
  )
}
export default SubmenuBar
