import React, { useCallback, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

import {
  scaleSelector,
  ZOOM_MODE,
  setScale,
  setZoomMode,
  zoomModeSelector
} from '../../modules/reducerPDFViewer'

const useStyles = makeStyles((theme) => ({
  root: { textTransform: 'none', padding: 0 },
  select: {
    textAlign: 'center',
    width: '200px',
    height: '40px',
    // backgroundColor: 'rgb(220,220,220)'
    backgroundColor: 'white'
  }
}))

const zoomOptions = [
  { id: 'z1', text: 'Ajuste de la página' },
  { id: 'z2', text: 'Ajuste de la anchura' },
  { id: 'z3', text: '50%' },
  { id: 'z4', text: '75%' },
  { id: 'z5', text: '100%' },
  { id: 'z6', text: '125%' },
  { id: 'z7', text: '150%' },
  { id: 'z8', text: '200%' },
  { id: 'z9', text: '300%' },
  { id: 'z10', text: '400%' },
  { id: 'z11', text: '500%' }
]

const PDFZoomSelect = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const scale = useSelector(scaleSelector)
  const zoomMode = useSelector(zoomModeSelector)
  const [zoomOption, setZoomOption] = useState('z1')

  const getZoomValue = useCallback(
    (zoomId) => {
      if (zoomId === 'z0') {
        return scale
      }
      let zoomValue = zoomOptions.find((zoom) => zoom.id === zoomId)
      return zoomValue.text.slice(0, -1) / 100
    },
    [scale]
  )

  const handleChangeZoomOption = useCallback(
    (e) => {
      //Cambio en zoomOption. Cambiar zoomMode

      setZoomOption(e.target.value)
      switch (e.target.value) {
        case 'z1':
          dispatch(setZoomMode(ZOOM_MODE.FIT_PAGE))
          break
        case 'z2':
          dispatch(setZoomMode(ZOOM_MODE.FIT_WIDTH))
          break
        default:
          dispatch(setScale(getZoomValue(e.target.value)))
      }
    },
    [dispatch, getZoomValue]
  )

  useEffect(() => {
    //Cambio en zoomMode o escala. Cambiar zoomOption

    switch (zoomMode) {
      case ZOOM_MODE.FIT_PAGE:
        setZoomOption('z1')
        break
      case ZOOM_MODE.FIT_WIDTH:
        setZoomOption('z2')
        break
      case ZOOM_MODE.SCALE:
      default:
        setZoomOption('z0')
    }
  }, [zoomMode, scale]) //Dejar scale

  return (
    <Tooltip title={'Tamaño'} arrow>
      <Button
        {...props}
        className={`${props.className} ${classes.root}`}
        component="div"
      >
        <Select
          className={classes.select}
          value={zoomOption}
          onChange={handleChangeZoomOption}
          variant="standard"
          disableUnderline
        >
          <MenuItem value={'z0'}>{`${parseInt(scale * 100.0)}%`}</MenuItem>
          <Divider />
          {zoomOptions.map((value, index) => (
            <MenuItem key={value.id} value={value.id}>
              {value.text}
            </MenuItem>
          ))}
        </Select>
      </Button>
    </Tooltip>
  )
}
export default PDFZoomSelect
