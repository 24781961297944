import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import LabelCheckBox from '../Common/LabelCheckBox'
import Title from './Title'
import StateColor from '../Common/StateColor'

import { houseStatesSelector } from '../../modules/reducerHouses'
import {
  selectHouseState,
  houseStateSelector,
  getClassifyHousesByState
} from '../../modules/reducerHousesFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column'
  },
  label: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'
  },
  colorMark: { width: '4px', height: '10px' }
}))

const StateItem = ({ id }) => {
  const selectedStates = useSelector(houseStateSelector)
  const housesByState = useSelector(getClassifyHousesByState)
  const st = housesByState.find((v) => v.stateId === id)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (e) => {
      dispatch(selectHouseState(id, e.target.checked))
    },
    [id, dispatch]
  )

  return (
    <LabelCheckBox
      label={
        <StateColor
          stateId={id}
          aditionalText={` (${st ? st.housesId.length : 0})`}
        />
      }
      checked={selectedStates.has(id)}
      onChange={handleChange}
    />
  )
}

const States = () => {
  const classes = useStyles()
  const states = useSelector(houseStatesSelector)

  return states.length > 0 ? (
    <div className={classes.root}>
      <Title text={'Disponibilidad'} />
      {states.map((st) => {
        return <StateItem key={st.id} id={st.id} />
      })}
    </div>
  ) : null
}
export default States
