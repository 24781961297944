// import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'

import TooltipContent from '../HousesRotator/TooltipContent'

import { getFilterHouses } from '../../modules/reducerHousesFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.gallery.background,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  cardWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const HousesGallery = () => {
  const classes = useStyles()
  const houses = useSelector(getFilterHouses)
  // const dispatch = useDispatch()

  return (
    <Grid
      container
      alignItems={'flex-start'}
      alignContent={'flex-start'}
      className={classes.root}
    >
      {houses.map((house) => (
        <Grid
          key={house.id}
          item
          sm={6}
          xs={12}
          className={classes.cardWrapper}
        >
          <TooltipContent houseId={house.id} />
        </Grid>
      ))}
    </Grid>
  )
}
export default HousesGallery
