// import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    opacity: ({ show }) => (show ? 1 : 0)
  },
  absolute: {
    position: 'absolute',
    left: ({ next }) => (next ? null : 10),
    right: ({ next }) => (next ? 10 : null),
    top: '50%',
    transform: 'translateY(-50%)'
  }
}))

const GalleryButton = ({ next, absolute, onClick, count, currentIdx }) => {
  const show = next ? currentIdx < count - 1 : currentIdx > 0
  const classes = useStyles({ show, absolute, next })

  return (
    <IconButton
      className={`${classes.root} ${absolute ? classes.absolute : ''}`}
      disabled={!show}
      onClick={onClick}
    >
      {next ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
    </IconButton>
  )
}
export default GalleryButton
