// import { useCallback } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import DocumentsMenu from './DocumentsMenu'

import { getCurrentHouse } from '../../modules/reducerHouses'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const HouseDocumentsMenu = () => {
  const house = useSelector(getCurrentHouse)
  // const dispatch = useDispatch()
  // const classes = useStyles()
  return house ? <DocumentsMenu docIds={house.documents} /> : null
}
export default HouseDocumentsMenu
